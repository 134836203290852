import {
  subscribe,
  getActiveUser,
  guardRoute,
  navigate,
  showSnack,
  UserRoles,
  maskString,
} from "../utils/utils";
import DataList from "./component/@list/list";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";

const TABLE_HEAD = [
  { id: "chan_name", label: "Name", alignRight: false },
  { id: "chan_sp_mssid", label: "MSSID", alignRight: false },
  { id: "chan_sp_username", label: "Username", alignRight: false },
  { id: "chan_sp_id", label: "Security ID", alignRight: false },
  { id: "chan_sp_secret_key", label: "Security Key", alignRight: false },
  { id: "chan_sp_endpoint", label: "End-Point", alignRight: false },
  { id: "_" },
];

const ChannelList = () => {
  const loggedUser = getActiveUser();

  guardRoute(false, loggedUser.role === UserRoles.admin && loggedUser.superAdmin);
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);

  const operationOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) =>{
        subscribe(
          `channel/delete`,
          selected.join(","),
          (error, result) => {
            executeFetch();
            showSnack(error ? error.message : result.message);
          },
          (loading) => onLoading(loading)
        );
      }
    },
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) =>
        navigate(
          `${AppRoutes.channels}/${AppRoutes.channel}?id=${selected.join("")}`,
          true
        ),
    }
  ];


  const executeFetch = () => {
    subscribe(
      "channel/find", null,
      (_, result) => {
        if (result) setDataList(result);
      },
      (loading) => onLoading(loading)
    );
  };

  useEffect(() => {
    setTimeout(() => {
      executeFetch();
    }, 500);
  }, []);

  return (
    <DataList
      sectionTitle="Channels"
      createNewLabel={
        loggedUser.role !== UserRoles.admin ? null : "Create Channel"
      }
      data={dataList}
      primaryKey="_id"
      filterColumn={"chan_name"}
      emptyIcon="contactless"
      orderIn="asc"
      loading={loading}
      onCreateNewItem={() =>
        navigate(`${AppRoutes.channels}/${AppRoutes.channel}`, true)
      }
      headers={TABLE_HEAD}
      rowData={(data) => {
        return {
          exclude: [],
          entries: [
            { value: data.chan_name },
            { value: maskString(data.chan_sp_mssid,4) },
            { value: data.chan_sp_username },
            { value: maskString(data.chan_sp_id, 10) },
            { value: maskString(data.chan_sp_secret_key, 8) },
            { value: maskString(data.chan_sp_endpoint, 10) },
          ],
        };
      }}
      selectionOptions={[]}
      filterOptions={[]}
      optionItems={operationOptions}
    />
  );
};
export default ChannelList;
