import {
  Grid,
  Typography,
  ListItemText,
  ListItem,
  List,
  CircularProgress,
  Icon,
  Avatar,
  IconButton,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContentText,
  Button,
} from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  subscribe,
  getParams,
  guardRoute,
  formatDate,
  fCurrency,
  getLogIcon,
  listLiveUpdate,
  titleCase,
  navigate,
  getPaymentStatus,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect, Fragment } from "react";
import AppRoutes from "../routing/routes";
import ReactJson from "react-json-view";

const Insights = () => {
  guardRoute();
  const [id] = useState(getParams().id);
  const [logList, setLogList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [trans, setTrans] = useState({});
  const [logData, setLogData] = useState(null);
  const [dialogVisible, showDialog] = useState(false);

  const executeFetch = (reFetch = false) => {
    Promise.all([
      subscribe(
        `transaction/information?id=${id}&type=pay`,
        null,
        (error, res) =>
          error ? navigate(`${AppRoutes.summary}`, true) : setTrans({ ...res }),
        (_loading) => setLoading(reFetch ? false : _loading)
      ),
      subscribe(
        `logs/find?id=${id}&type=trans`,
        null,
        (_, res) => setLogList(res),
        (_loading) => setLoading(false)
      ),
    ]);
  };

  useEffect(() => {
    setTimeout(() => executeFetch(), 500);
    // eslint-disable-next-line
  }, [id]);

  listLiveUpdate(
    logList,
    (match) => id === match.logs_trans,
    (_) => executeFetch(true)
  );

  const handleDialog = (show, data = null) => {
    setLogData(data);
    showDialog(show);
    console.log(data)
  };

  return (
    <EditPage
      title="Transaction Insights"
      showBtn={false}
      validated={false}
      px={0}
      py={0}
      status={{}}
      onSave={() => {}}
      spacing={4}
      onFinish={() => {}}
    >
      {loading && (
        <Grid item xs={12} textAlign="center">
          <CircularProgress color="primary" size="100px" />
        </Grid>
      )}
      {!loading && (
        <Grid item xs={12} sm={4}>
          <Typography align="left" variant="body1">
            Initiated at
          </Typography>

          <Typography color="text.disabled" align="left" variant="h6" mt={1}>
            {formatDate(trans.trans_timestamp)}
          </Typography>

          <Typography align="left" variant="body1" mt={3}>
            Amount
          </Typography>

          <Typography color="text.disabled" align="left" variant="h6" mt={1}>
            {fCurrency(trans.trans_amount)}
          </Typography>

          <Typography align="left" variant="body1" mt={3}>
            Transaction Reference ID
          </Typography>

          <Typography color="text.disabled" align="left" variant="h6" mt={1}>
            {trans.trans_reference_id}
          </Typography>

          <Typography align="left" variant="body1" mt={3}>
            MNO Rereference ID
          </Typography>

          <Typography color="text.disabled" align="left" variant="h6">
            {(trans.trans_mno_reference || "").length > 1
              ? trans.trans_mno_reference
              : "Not Provided"}
          </Typography>

          <Typography align="left" variant="body1" mt={3}>
            Status
          </Typography>

          <Typography
            color={`${getPaymentStatus(trans.trans_status).color}.dark`}
            align="left"
            variant="h6"
          >
            {trans.trans_status}
          </Typography>

          {trans.trans_remarks && (
            <Typography
              align="left"
              variant="body1"
              color="text.disabled"
              mt={1}
            >
              {trans.trans_remarks}
            </Typography>
          )}
        </Grid>
      )}

      {!loading && (
        <Grid item xs={12} sm={8}>
          <Typography
            sx={{ ml: 2.2, mb: 1 }}
            color="text.disabled"
            align="left"
            variant="h6"
          >
            Live Updates as happening on {trans.trans_client_id}
          </Typography>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {logList.map((log) => {
              const props = getLogIcon(log.logs_event);
              return (
                <ListItem alignItems="flex-start">
                  <Avatar variant="circle" sx={{ bgcolor: props.color, mr: 3 }}>
                    <Icon children={props.icon} />
                  </Avatar>
                  <ListItemText
                    primary={`${formatDate(
                      log.logs_timestamp,
                      "hh:mm:ss A, DD MMMM YYYY"
                    )} (${titleCase(log.logs_key || "system")})`}
                    secondary={
                      <Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {log.logs_desc}
                        </Typography>
                      </Fragment>
                    }
                  />
                  {log.logs_data && (
                    <IconButton
                      children={<Icon children="code" />}
                      onClick={() => handleDialog(true, log.logs_data)}
                    />
                  )}
                </ListItem>
              );
            })}
          </List>
        </Grid>
      )}

      {dialogVisible && (
        <Dialog
          open={dialogVisible}
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ px: 4, mt: 2 }} id="alert-dialog-title">
            Data associated with log entry
          </DialogTitle>
          <DialogContent>
            <DialogContentText xs={{ mt: 5 }} id="alert-dialog-description">
              <Grid container spacing={4} sx={{ py: 3, px: 3 }}>
                <Grid item xs={12} sm={5}>
                  <ReactJson
                    style={{ minWidth: 800 }}
                    theme={"tube"}
                    src={logData}
                    name={null}
                    displayDataTypes={false}
                    sortKeys={true}
                    quotesOnKeys={false}
                    displayObjectSize={false}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: 3, mr: 5 }}>
            <Button onClick={() => handleDialog(false)}>DISMISS</Button>
          </DialogActions>
        </Dialog>
      )}
    </EditPage>
  );
};

export default Insights;
