import { Grid } from "@mui/material";
import {
  guardRoute,
  fCurrency,
  maskString,
  fNumber,
  formatDate,
  fCurrencyShort,
  getActiveUser,
  UserRoles,
  formatPhoneNumber,
  getTransferStatus,
  navigate,
  showSnack,
  subscribe,
  watchLiveData,
} from "../utils/utils";
import Page from "./component/page";
import { useState, useEffect } from "react";
import SummaryCard from "./component/summary-card";
import DataList from "./component/@list/list";
import AppRoutes from "../routing/routes";

const getTableHead = () => {
  const loggedUser = getActiveUser();
  const heads = [
    { id: "trans_timestamp", label: "Requested On", alignRight: false },
    { id: "_id", label: "Transfer ID", alignRight: false },
    { id: "trans_amount", label: "Amount", alignRight: false },
    { id: "trans_account", label: "Account", alignRight: false },
    { id: "trans_account_org", label: "Channel", alignRight: false },
  ];

  if (loggedUser.role === UserRoles.admin) {
    heads.push({
      id: "trans_merchant_name",
      label: "Merchant",
      alignRight: false,
    });
    heads.push({ id: "trans_status", label: "Status", alignRight: false });
    heads.push({ id: "__" });
  } else {
    heads.push({ id: "trans_status", label: "Status", alignRight: false });
  }

  return heads;
};
const _state = {
  balance: 0,
  minDisburse: 0,
  minTransaction: 0,
  request: false,
  disburse: false,
};
const DisbursementSummary = () => {
  guardRoute();
  const [transList, setTransList] = useState([]);
  const [summaryList, setSummary] = useState([]);
  const [loading, onLoading] = useState(false);
  const loggedUser = getActiveUser();
  const [transferState, setTransferState] = useState(_state);
  const [filterKey, setFilterkey] = useState("trans_timestamp");

  const executeUpdate = (status, id) => {
    subscribe(
      `transfer/update`,
      { _id: id, trans_status: status },
      (error, result) => {
        showSnack(error ? error.message : result.message);
      },
      (loading) => onLoading(loading)
    );
  };

  const executeFetch = (refresh) => {
    setTimeout(() => {
      subscribe(
        `transfer/find`,
        null,
        (_, result) => {
          if (result) setTransList(result);
        },
        (loading) => onLoading(refresh ? loading: false)
      );

      subscribe(
        `transfer/state`,
        null,
        (_, result) => {
          if (result) setTransferState(result);
        },
        (loading) => onLoading(loading)
      );

      subscribe(
        `transfer/summary`,
        null,
        (_, result) => {
          if (result) setSummary(result);
        },
        (loading) => onLoading(loading)
      );
    }, 500);
  };

  const actionOptions = [
    {
      icon: "check",
      value: "transfered",
      label: "Approve",
      onClick: (value, selected) => executeUpdate(2, selected.join(",")),
    },
    {
      icon: "query_builder",
      value: "pending",
      label: "Pending",
      onClick: (value, selected) => executeUpdate(1, selected.join(",")),
    },
    {
      icon: "timer",
      value: "decline",
      label: "Decline",
      onClick: (value, selected) => executeUpdate(3, selected.join(",")),
    },
  ];
  const filterOptions = [
    { value: "trans_status", label: "Status", onClick: setFilterkey },
    { value: "trans_merchant_name", label: "Merchant", onClick: setFilterkey },
  ];

  useEffect(() => {
    executeFetch(true);
    watchLiveData("transfer", (data) => {
      const fetch =
        (loggedUser.role !== UserRoles.admin &&
          data.trans_merchant === loggedUser.id) ||
        loggedUser.role === UserRoles.admin;

      if (fetch) executeFetch(false);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Page title="Dashboard">
      <Grid container>
        {summaryList && summaryList.length > 0 && (
          <Grid container spacing={6} sx={{ px: "2%" }} mb={5}>
            {summaryList.map((summary, index) => {
              return (
                <Grid item xs={12} sm={6} md={3}>
                  <SummaryCard
                    title={summary.title}
                    color={summary.color}
                    key={summary.color}
                    total={summary.counter}
                    formatter={
                      index < 2
                        ? fNumber
                        : parseInt(summary.counter) >= 10000000
                        ? fCurrencyShort
                        : fCurrency
                    }
                    icon={summary.icon}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            sectionTitle="Disbursement"
            data={transList}
            primaryKey="_id"
            filterColumn={filterKey}
            emptyIcon="paid"
            createNewIcon={loggedUser.auto ? "settings_suggest" : "add"}
            loading={loading}
            createNewLabel={
              transferState && transferState.disburse
                ? transferState.request
                  ? "Request Payout"
                  : "Preferences"
                : null
            }
            onCreateNewItem={() =>
              navigate(
                `${AppRoutes.disbursement}/${AppRoutes.requestPay}`,
                true
              )
            }
            headers={getTableHead()}
            rowData={(data) => {
              return {
                exclude: [
                  data.trans_status === "pending" ? "pending" : "",
                  data.trans_status === "transferred" ? "transfered" : "",
                  data.trans_status === "declined" ? "decline" : "",
                  loggedUser.role !== UserRoles.admin ? "decline" : "",
                ],
                entries: [
                  {
                    value: formatDate(
                      data.trans_timestamp,
                      "H:mm A, DD MMM YY"
                    ),
                  },
                  { value: maskString(data.trans_id, 6, 4) },
                  { value: fCurrency(data.trans_amount) },
                  {
                    value: !data.trans_type
                      ? formatPhoneNumber(data.trans_account, true)
                      : maskString(data.trans_account, 6, 4),
                  },
                  {
                    value:
                      data.trans_type === 1
                        ? "Internal"
                        : data.trans_type === 2
                        ? "Bank"
                        : "Mobile",
                  },
                  loggedUser.role === UserRoles.admin
                    ? { value: data.trans_merchant_name }
                    : { value: null },
                  { ...getTransferStatus(data.trans_status) },
                ],
              };
            }}
            selectionOptions={
              loggedUser.role === UserRoles.admin ? actionOptions : []
            }
            filterOptions={
              loggedUser.role === UserRoles.admin ? filterOptions : []
            }
            optionItems={
              loggedUser.role === UserRoles.admin ? actionOptions : []
            }
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default DisbursementSummary;
