import {
  Grid,
} from "@mui/material";
import {
  getParams,
  navigate,
  isError,
  validateFields,
  cleanObject,
  subscribe,
  getActiveUser,
  UserRoles,
  guardRoute,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";

const chanRef = {
  chan_name: "",
  chan_id: 1,
  chan_sp_id: "",
  chan_sp_secret_key: "",
  chan_sp_mssid: "000000000000",
  chan_sp_username: "N/A",
  chan_sp_password: "N/A",
  chan_sp_endpoint: "",
};

const validationRef = {
  chan_name: { error: true, touched: false },
  chan_id: { error: false, touched: true },
  chan_sp_id: { error: true, touched: false },
  chan_sp_secret_key: { error: true, touched: false },
  chan_sp_mssid: { error: false, touched: true },
  chan_sp_username: { error: false, touched: true },
  chan_sp_password: { error: false, touched: true },
  chan_sp_endpoint: { error: true, touched: false }
};

const ChannelEdit = () => {
  const loggedUser = getActiveUser();
  guardRoute(
    false,
    loggedUser.role === UserRoles.admin && loggedUser.superAdmin
  );
  const { id } = getParams();
  const [channel, setChannel] = useState(chanRef);
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });

  useEffect(() => {
    setTimeout(() => {
      subscribe(`channel/find?id=${id || "-"}`, null,
      (_, result) => {
        if(result && result.chan_id){
           onValueChange(result.chan_name, "chan_name");
           onValueChange(result.chan_id, "chan_id");
           onValueChange(result.chan_sp_username, "chan_sp_username");
           onValueChange(result.chan_sp_password, "chan_sp_password");
           onValueChange(result.chan_sp_secret_key, "chan_sp_secret_key");
           onValueChange(result.chan_sp_id, "chan_sp_id");
           onValueChange(result.chan_sp_endpoint, "chan_sp_endpoint");
           onValueChange(result.chan_sp_mssid, "chan_sp_mssid");
           setChannel({...chanRef, ...result})
        }
      },
       (loading) =>
        setStatus({ ...{ loading: loading } })
      );
    }, 500);
    // eslint-disable-next-line
  }, [id]);

  const createUpdateChannel = () => {
    
    subscribe(
      `channel/${id ? "update" : "create"}`,
      cleanObject({ ...channel, _id: id }),
      (error, result) =>
        setStatus({
          ...{
            error: error != null,
            message: result ? result.message : error.message,
          },
        }),
      (loading) => setStatus({ ...{ loading: loading } })
    );
  };

  const onValueChange = (value, tag) => {
    channel[tag] = value;
    if (tag.includes("_name")) {
      validation.chan_name = { error: value.length <= 4, touched: true };
    }

    if (tag.includes("_endpoint")) {
      validation.chan_sp_endpoint = {
        error: !(value.includes("http") && value.split("/").length > 1),
        touched: true,
      };
    }

    if (tag.includes("_password")) {
      validation.chan_sp_password = { error: value.length < 3, touched: true };
    }

    if (tag.includes("_sp_mssid")) {
      validation.chan_sp_mssid = { error: value.length < 10, touched: true };
    }

    if (tag.includes("_username")) {
      validation.chan_sp_username = { error: value.length < 3, touched: true };
    }

    if (tag === "chan_id") {
      validation.chan_id = {
        error: value.length === 0 || parseInt(value) < 1,
        touched: true,
      };
    }

    if (tag.includes("_sp_id")) {
      validation.chan_sp_id = { error: value.length < 3, touched: true };
    }

    if (tag.includes("_secret_key")) {
      validation.chan_sp_secret_key = { error: value.length < 3, touched: true }
    }

    setChannel({ ...channel }); 
    setValidation({ ...validation });
    setValidated(validateFields(validation));
  };

  return (
    <EditPage
      title={id ? "Edit Channel" : "Create Channel"}
      status={status}
      onSave={() => createUpdateChannel()}
      validated={validated && !status.loading}
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setChannel({ ...chanRef });
          navigate(`${AppRoutes.channels}`, true);
        }
      }}
    >
      <Grid item xs={12} sm={6}>
        <EasyTextEdit
          placeholder="Channel name"
          type="text"
          id="chan_name"
          errorText="Invalid channel name"
          error={isError(validation.chan_name)}
          disabled={status.loading}
          value={channel.chan_name}
          icon="contactless"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <EasyTextEdit
          placeholder="Service ID"
          type="text"
          id="chan_id"
          errorText="Invalid Service ID"
          error={isError(validation.chan_id)}
          disabled={status.loading}
          value={channel.chan_id}
          icon="tag"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <EasyTextEdit
          placeholder="Channel ID"
          type="text"
          id="chan_sp_id"
          errorText="Invalid channel ID"
          error={isError(validation.chan_sp_id)}
          disabled={status.loading}
          value={channel.chan_sp_id}
          icon="fact_check"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <EasyTextEdit
          placeholder="Channel Username"
          type="text"
          id="chan_sp_username"
          errorText="Invalid channel username"
          error={isError(validation.chan_sp_username)}
          disabled={status.loading}
          value={channel.chan_sp_username}
          icon="badge"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <EasyTextEdit
          placeholder="Channel Password"
          type="text"
          id="chan_sp_password"
          errorText="Invalid channel password"
          error={isError(validation.chan_sp_password)}
          disabled={status.loading}
          value={channel.chan_sp_password}
          icon="password"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <EasyTextEdit
          placeholder="Channel Secret"
          type="text"
          id="chan_sp_secret_key"
          errorText="Invalid channel Key"
          error={isError(validation.chan_sp_secret_key)}
          disabled={status.loading}
          value={channel.chan_sp_secret_key}
          icon="password"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} sm={7}>
        <EasyTextEdit
          placeholder="Endpoint URL"
          type="text"
          id="chan_sp_endpoint"
          errorText="Invalid endpoint URL"
          error={isError(validation.chan_sp_endpoint)}
          disabled={status.loading}
          value={channel.chan_sp_endpoint}
          icon="public"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} sm={5}>
        <EasyTextEdit
          placeholder="Channel MSSID"
          type="text"
          id="chan_sp_mssid"
          errorText="Invalid channel MSSID"
          error={isError(validation.chan_sp_mssid)}
          disabled={status.loading}
          value={channel.chan_sp_mssid}
          icon="book_online"
          onChange={onValueChange}
        />
      </Grid>
    </EditPage>
  );
};

export default ChannelEdit;
