import PropTypes from "prop-types";
import { useState } from "react";
import { coreStyles } from "../../theme/style";
import {
  InputAdornment,
  Icon,
  FormControl,
  OutlinedInput,
  IconButton,
  FormHelperText,
} from "@mui/material";

EasyTextEdit.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  password: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  errorText: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  endAdornment: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

export default function EasyTextEdit({
  disabled = false,
  error = false,
  type = "text",
  errorText = null,
  placeholder,
  endAdornment,
  multiline = false,
  id,
  password = false,
  onChange,
  icon,
  value = "",
  sx,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const appStyle = coreStyles();
  const ado = password ? (
    <InputAdornment
      onClick={() => setShowPassword(!showPassword)}
      position="end"
    >
      <IconButton edge="center">
        <Icon
          children={showPassword ? "visibility" : "visibility_off"}
          className={appStyle.input_icons}
        />
      </IconButton>
    </InputAdornment>
  ) : (
    endAdornment
  );
  return (
    <FormControl variant="filled" fullWidth sx={{ ...sx }}>
      <OutlinedInput
        placeholder={placeholder}
        type={password ? (showPassword ? "text" : "password") : type}
        error={error}
        disabled={disabled}
        inputlabelprops={{ shrink: true }}
        className={appStyle.inputs}
        value={value}
        multiline={multiline}
        rows={multiline ? 8: 1}
        onChange={(event) => onChange(event.currentTarget.value, id)}
        startAdornment={
          icon && (<InputAdornment position="end">
            <IconButton edge="center">
              <Icon children={icon} className={appStyle.input_icons} />
            </IconButton>
          </InputAdornment>)
        }
        endAdornment={ado}
      />
      {error && errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </FormControl>
  );
}
