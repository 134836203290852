import { Typography, Grid } from "@mui/material";
import Logo from "./logo";
export default function SupportedMno() {
  return (
    <Grid container>
      <Grid item xs={12} mt={3}>
        <Typography color="text.disabled" align="left" variant="body2" mb={1}>
          We currently support
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Logo width={50} height={50} image="airtel-money.png" />
      </Grid>

      {/* <Grid item xs={2}>
        <Logo width={50} height={50} image="ezy-pesa.png" />
      </Grid> */}
      <Grid item xs={2}>
        <Logo width={50} height={50} image="halo-pesa.png" />
      </Grid>

      {/* <Grid item xs={2}>
        <Logo width={50} height={50} image="m-pesa.png" />
      </Grid>
 */}
      <Grid item xs={2}>
        <Logo width={50} height={50} image="t-pesa.png" />
      </Grid>

      <Grid item xs={2}>
        <Logo width={50} height={50} image="tigo-pesa.png" />
      </Grid>
    </Grid>
  );
}
