import AppRoutes from "../routing/routes";
import SessionManager from "clientside-session-manager";
import { replace } from "lodash";
import numeral from "numeral";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppStore from "./@redux/store";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import copy from "copy-to-clipboard";
import { showSnackBar } from "../utils/@redux/snackbar";
import { phone } from "phone";
import { passwordStrength } from "check-password-strength";
import { red, blue, teal } from "@mui/material/colors";

export const baseUrl = () =>
  process.env.NODE_ENV === "production"
    ? "https://api.easypay.co.tz/v2/"
    : "http://localhost:28000/v2/";
const authTag = "authenticated",
  tag2Fa = "2fa",
  sessionTimeout = process.env.NODE_ENV === "production" ? 30 : 86400,
  tagQr = "qr",
  tagKey = "apiKey",
  tagConfig = "configured",
  tempUid = "_uid";

export const refreshTimeout = 4000; //milliseconds

export const paymentTimeout = 75; //seconds

export const filesUrl = "https://files.easypay.co.tz/assets/";

export const acceptedMimes = "image/jpg,image/png,application/pdf";

export const maxFileSize = 2097152;

export const handleRefresh = (_trans, reload = false) => {
  setTimeout(() => {
    if (reload) {
      window.location.reload();
    } else {
      window.location.href =
        _trans.trans_redirect_url || "https://my.easypay.co.tz";
    }
  }, refreshTimeout);
};

export const InitSessionObserver = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.location.pathname.includes(AppRoutes.dashboard)) {
      SessionManager.updateToken(`token-${new Date().getTime()}`);
      var timeoutId = parseInt(sessionStorage.getItem("timeOut") || "-1");
      clearTimeout(parseInt(timeoutId));
      timeoutId = setTimeout(() => logout(), sessionTimeout * 1000 * 60);
      sessionStorage.setItem("timeOut", String(timeoutId));
    }
  }, [location]);
};

export const generateUniqueId = () => uuidv4();

export const getLogIcon = (event) => {
  switch (event) {
    case "create/add":
      return { color: teal[500], icon: "done_all" };
    case "update/view":
      return { color: blue[500], icon: "app_registration" };
    case "delete/unsuccessful":
      return { color: red[500], icon: "close" };
    default:
      return { color: "black", icon: "settings_suggest" };
  }
};

export const initApp = () => {
  if (!localStorage.getItem(tempUid)) {
    localStorage.setItem(tempUid, generateUniqueId());
  }
};

export const getHandlerPathProps = (payload) => {
  const parts = payload.path.split("/");
  return {
    path: parts[0],
    event: parts[parts.length - 1].split("?")[0],
    returnPath: `${payload.nodeId}/${payload.path}`,
  };
};

export const watchLiveData = (event, callback) => {
  const socket = AppStore.getState().socket.instance;
  const eventCallback = (data) => callback(data);
  socket.off(`${event}`, eventCallback);
  socket.on(`${event}`, eventCallback);
};

export const liveData = (event, filterMatches, callback) => {
  watchLiveData(event, (data) =>
    filterMatches(data) ? callback(data) : () => {}
  );
};

export const listLiveUpdate = (
  list,
  filterMatches,
  callback,
  event = "log"
) => {
  watchLiveData(event, (data) => {
    if (filterMatches(data)) {
      const _list = [].concat(list);
      const activeIndex = _list.findIndex((item) => item._id === data._id);
      if (activeIndex < 0) {
        _list.push(data);
      } else {
        _list[activeIndex] = data;
      }
      callback(_list);
    }
  });
};

export const subscribe = (
  path,
  content = null,
  callback = null,
  onLoading = () => {},
  headers = {}
) => {
  onLoading && onLoading(true);
  const payload = {
    path: path,
    data: content,
    headers: cleanObject({
      "source-app": "web",
      "x-api-key": SessionManager.getDetail(tagKey),
      ...headers,
    }),
    nodeId: localStorage.getItem(tempUid),
  };
  const socket = AppStore.getState().socket.instance;
  return new Promise((resolve) => {
    socket.timeout(30000).emit("easypay", payload, (error, data) => {
      onLoading && onLoading(false);
      callback &&
        callback(
          error || data.error ? data || error : null,
          error || data.error ? null : data
        );
      resolve(error || data.error ? null : data);
    });
  });
};

export const navigate = (path, dashboard = false) => {
  AppStore.getState().navigator.navigate(
    `${dashboard ? AppRoutes.dashboard : AppRoutes.auth}/${path}`
  );
};

export const loggedInitSession = (response) => {
  SessionManager.create(
    `token-${new Date().getTime()}`,
    response.data,
    sessionTimeout
  );
  if (SessionManager.getDetail(tag2Fa)) {
    navigate(
      SessionManager.getDetail(tagConfig)
        ? AppRoutes.twoFactorAuth
        : AppRoutes.twoFactorConfig
    );
  } else {
    logAuthenticated();
  }
};
export const getParams = () => {
  if (window.location.search) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const objs = Object.fromEntries(urlSearchParams.entries());
    var _objs = {};
    for (const key in objs) {
      _objs[`${key}`] =
        objs[key] === "true" || objs[key] === "false"
          ? JSON.parse(objs[key])
          : objs[key];
    }
    return _objs;
  } else {
    return {};
  }
};
export const guardRoute = (recover = false, allowed = true) => {
  if (recover && recover === true) {
    if (!getParams().code) navigate(AppRoutes.login);
  } else {
    const valid = SessionManager.exists(),
      path = window.location.pathname,
      deepLink = path.includes(".well-known"),
      navToLogin =
        !valid &&
        ((!path.includes(AppRoutes.login) &&
          !path.includes(AppRoutes.forgot) &&
          !path.includes(AppRoutes.recover)) ||
          path.includes(AppRoutes.twoFactorAuth) ||
          !allowed);
    if (navToLogin && !deepLink) {
      window.location.href = `${AppRoutes.auth}/${AppRoutes.login}`;
      return;
    }

    if ((valid && !deepLink) || !allowed) {
      const data = SessionManager.getAll(),
        navToSummary =
          (data.authenticated && !path.includes(AppRoutes.dashboard)) ||
          !allowed,
        navTo2faConfig =
          data[tag2Fa] &&
          !data.configured &&
          !data.authenticated &&
          !path.includes(AppRoutes.twoFactorConfig),
        navTo2faAuth =
          data[tag2Fa] &&
          data.configured &&
          !data.authenticated &&
          !path.includes(AppRoutes.twoFactorAuth);

      if (navTo2faConfig) navigate(AppRoutes.twoFactorConfig);
      if (navTo2faAuth) navigate(AppRoutes.twoFactorAuth);
      if (navToSummary) navigate(AppRoutes.summary, true);
    }
  }
};

export const updateAddSessionValue = (key, value, update = false) => {
  if (update) {
    SessionManager.updateDetail(key, value);
  } else {
    SessionManager.addDetail(key, value);
  }
};

export const logAuthenticated = (configure = false) => {
  updateAddSessionValue(authTag, true);
  if (configure) {
    updateAddSessionValue(tagConfig, configure, true);
  }
  navigate(AppRoutes.summary, true);
};

export const logout = () => {
  SessionManager.destroy();
  localStorage.removeItem(tempUid);
  initApp();
  navigate(AppRoutes.login);
};

export const getQr = () => {
  return SessionManager.getDetail(tagQr);
};

export const getActiveUser = () => {
  return SessionManager.getAll();
};

export const fCurrency = (number, currency = "Tsh") => {
  return `${currency} ${numeral(number).format("0,0")}`;
};

export const fCurrencyShort = (number, currency = "Tsh") => {
  return `${currency} ${numeral(number).format("0,0 a")}`;
};

export const fPercent = (number) => {
  return numeral(number / 100).format("0.0%");
};

export const fNumber = (number) => {
  return numeral(number).format();
};

export const fShortenNumber = (number) => {
  return replace(numeral(number).format("0.00a"), ".00", "");
};

export const fData = (number) => {
  return numeral(number).format("0.0 b");
};

export const getUserRole = (role) => {
  return {
    value:
      role === UserRoles.admin
        ? "Admin"
        : role === UserRoles.merchant
        ? "Merchant"
        : role === UserRoles.supervisor
        ? "Supervisor"
        : "Sales",
  };
};

export const UserRoles = {
  admin: 1,
  merchant: 2,
  supervisor: 3,
  sales: 4,
};

export const TransStatus = {
  pending: "PENDING",
  completed: "COMPLETED",
  failed: "FAILED",
  paying: "PAYING",
  notFound: "NOT FOUND",
};

export const Statuses = {
  pending: 1,
  approved: 2,
  suspended: 3,
};

export const getUserStatus = (status) => {
  return {
    isLabel: true,
    value:
      status === Statuses.pending
        ? "Pending"
        : status === Statuses.approved
        ? "Approved"
        : "Suspended",
    color:
      status === Statuses.pending
        ? "warning"
        : status === Statuses.approved
        ? "success"
        : "error",
  };
};

export const getPaymentStatus = (status, plain = false) => {
  return {
    isLabel: true,
    value:
      status === TransStatus.completed
        ? titleCase(TransStatus.completed)
        : status === TransStatus.pending
        ? titleCase(TransStatus.pending)
        : status === TransStatus.failed
        ? titleCase(TransStatus.failed)
        : titleCase(TransStatus.paying),
    color:
      status === TransStatus.completed
        ? "success"
        : status === TransStatus.pending
        ? "warning"
        : status === TransStatus.failed
        ? "error"
        : "info",
    icon:
      status === TransStatus.completed
        ? plain
          ? "check"
          : "check_circle"
        : status === TransStatus.pending
        ? plain
          ? "query_builder"
          : "query_builder"
        : status === TransStatus.failed
        ? plain
          ? "close"
          : "highlight_off"
        : "error_outline",
  };
};

export const getTransferStatus = (status) => {
  return {
    isLabel: true,
    value:
      status === "pending"
        ? "Pending"
        : status === "declined"
        ? "Declined"
        : "Transferred",
    color:
      status === "pending"
        ? "warning"
        : status === "declined"
        ? "error"
        : "success",
  };
};

export const getAvatarLabel = (objects, mKey) => {
  var keys = Object.keys(objects),
    label = null;
  for (let step = 0; step < keys.length; step++) {
    if (keys[step].includes(mKey) && objects[keys[step]]) {
      label = objects[keys[step]];
    }
  }
  if (label) {
    return `${label.split(" ")[0]}`.substring(0, 1).toUpperCase();
  }
  return ".";
};

export const validateUrl = (value) => {
  return /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
};

export const validateEmail = (value) => {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhone = (value) => {
  const phoneNumber = formatPhoneNumber(value, true);
  const validate = phone(phoneNumber, { country: "TZ" });
  return validate && validate.isValid && phoneNumber.length >= 12;
};

export const validateName = (value) => {
  // eslint-disable-next-line
  const valid = /^s*([A-Za-z]{1,}([\.,] |[-']| ))+[A-Za-z]+\.?\s*$/.test(value);
  return String(value).split(/(\s+)/).length === 3 && valid;
};

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const validatePassword = (value) => {
  const level = passwordStrength(value).value;
  return {
    level:
      level === "Too weak"
        ? 25
        : level === "Weak"
        ? 50
        : level === "Medium"
        ? 75
        : 100,
    label: level,
  };
};

export const formatDate = (timestamp, format = "LLL") => {
  return moment(timestamp).format(format);
};

export const getStartTimeInHours = (date) => {
  const setTimeStamp = moment(date),
    startOfDay = moment().startOf("day");
  return setTimeStamp.diff(startOfDay, "minutes") / 60;
};

export const getStartTimeAsDate = (hours) => {
  return moment().startOf("day").add(hours, "hours").toDate();
};

export const getStartDate = (days) => {
  return moment().startOf("day").add(days, "days").toDate();
};

export const getTimeInMills = (days = 0) => {
  return moment().add(days, "days").valueOf();
};

export const startWorkTime = () => {
  return moment().startOf("day").add(7, "hours").toDate();
};

export const showSnack = (message) => {
  AppStore.dispatch(showSnackBar({ message: message }));
};

export const copyText = (text) => {
  copy(text);
};

export const maskString = (text, part = 8, end = part / 2) => {
  return text.length < part
    ? text
    : `${text.substring(0, part)}...${text.substring(text.length - end)}`;
};

export const formatPhoneNumber = (phoneNumber, withCode = true) => {
  var formatted = "",
    phone = phoneNumber.trim();
  if (phone.startsWith("255")) formatted = phone;
  else if (phone.startsWith("+255"))
    formatted = phone.substring(1, phone.length);
  else if (phone.startsWith("0"))
    formatted = `255${phone.substring(1, phone.length)}`;
  else formatted = `255${phone}`;
  return withCode ? formatted : formatted.substring(3, formatted.length);
};

export const uploadFile = (file, onDone, onProgress) => {
  if (!file) {
    onDone(null);
    onProgress(0);
    return;
  } else {
    var formData = new FormData();
    formData.append("files", file);
    formData.append("uid", localStorage.getItem(tempUid));
    var xhttp = new XMLHttpRequest();
    xhttp.responseType = "json";
    xhttp.open("POST", `${baseUrl()}attachment/create`, true);
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        onProgress(0);
        onDone(xhttp.response.file);
      }
    };

    xhttp.upload.onprogress = (evt) => {
      if (evt.lengthComputable) {
        var progress = Math.ceil((evt.loaded / evt.total) * 100);
        onProgress(progress);
      }
    };
    xhttp.send(formData);
  }
};

export const isError = (field) => {
  return field.error && field.touched;
};

export const validateFields = (fields) => {
  var validated = [];
  Object.keys(fields).forEach((key) => {
    validated.push(!fields[key].error && fields[key].touched);
  });
  return validated.filter((entry) => !entry).length === 0;
};

export const cleanObject = (data) => {
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "string" && data[key].trim().length <= 0) {
      delete data[key];
    }
  });
  return data;
};

export const formatMills = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  var label = "";
  if (minutes > 0) {
    label = `${minutes}m`;
  }

  if (seconds > 0) {
    label = `${label} ${seconds}s`;
  }
  return label;
};
