import { Grid } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  navigate,
  guardRoute,
  subscribe,
  UserRoles,
  getActiveUser,
  isError,
  validateFields,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";
import MUIRichTextEditor from "mui-rte";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";

const mailRef = {
  subject: "",
  message: "",
  emails:""
};

const validationRef = {
  subject: { error: true, touched: false },
  message: { error: true, touched: false }
};

const Mailing = () => {
  const loggedUser = getActiveUser();
  guardRoute(false, loggedUser.role === UserRoles.admin);
  const [validation, setValidation] = useState(validationRef);
  var [mail, setMail] = useState(mailRef);
  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });


  const convertDataToEditorState = (content) => {
    const contentHTML = convertFromHTML(content);
    const state = ContentState.createFromBlockArray(
      contentHTML.contentBlocks,
      contentHTML.entityMap
    );
    return JSON.stringify(convertToRaw(state));
  };

  const sendMail = () => {
    subscribe(
      `communicate/mail`,
      mail,
      (error, result) =>
        setStatus({
          ...{
            error: error != null,
            message: result ? result.message : error.message,
          },
        }),
      (loading) => setStatus({ ...{ loading: loading } })
    );
  };

  const onValueChange = (value, tag) => {
    mail[tag] = value;
    setMail({ ...mail });
    if (tag.includes("subject")) {
      validation.subject = {
        error: !(mail.subject.split(" ").length > 1 && mail.subject.length > 8),
        touched: true,
      };
    }
    if (tag.includes("message")) {
      validation.message = {
        error: mail.message === "<p><br></p>",
        touched: true,
      };
    }
    setValidation({ ...validation })
  };

  return (
    <EditPage
    title ={"Notify users"}
      btnLabel="Send Now"
      status={status}
      onSave={() => sendMail()}
      validated={validateFields(validation)}
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setMail({ ...mail });
          navigate(`${AppRoutes.dashboard}/${AppRoutes.summary}`, true);
        }
      }}
    >
      <Grid item xs={12} md={6}>
        <EasyTextEdit
          placeholder="Mail Subject"
          type="text"
          id="subject"
          errorText="Invalid mail subject, should be at least two words"
          error={isError(validation.subject)}
          disabled={status.loading}
          value={mail.subject}
          icon="subject"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <EasyTextEdit
          placeholder="Comma separated email addresses (Optional)"
          type="text"
          id="emails"
          errorText=""
          error={false}
          disabled={status.loading}
          value={mail.emails}
          icon="alternate_email"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} sx={{ mb: 4 }}>
        <MUIRichTextEditor
          label="Mail content..."
          value={convertDataToEditorState("")}
          onChange={(state) =>
            onValueChange(stateToHTML(state.getCurrentContent()), "message")
          }
        />
        {/* <EasyTextEdit
          placeholder="Mail body"
          type="text"
          id="message"
          multiline={true}
          errorText="Invalid message, should be atleast 50 characters"
          error={mail.message.length < 50}
          disabled={status.loading}
          value={mail.message}
          icon="forum"
          onChange={onValueChange}
        /> */}
      </Grid>
    </EditPage>
  );
};

export default Mailing;
