import PropTypes from "prop-types";
import { useState } from "react";
import { filter } from "lodash";
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Icon,
  Avatar,
  LinearProgress,
} from "@mui/material";
import { Scrollbar, Page } from "../";
import { ListToolbar, SearchNotFound, MoreMenu, ListHeader } from "../@list";
import { coreStyles } from "../../../theme/style";
import Label from "../label";
import {getAvatarLabel } from "../../../utils/utils";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const applySortFilter = (array, comparator, query, column) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_data) =>
        String(_data[column]).toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
};

DataList.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.array,
  sectionTitle: PropTypes.string,
  createNewLabel: PropTypes.string,
  primaryKey: PropTypes.string.isRequired,
  filterColumn: PropTypes.string.isRequired,
  optionItems: PropTypes.array,
  selectionOptions: PropTypes.array,
  rowData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  emptyIcon: PropTypes.string.isRequired,
  filterOptions: PropTypes.array,
  avatarKey: PropTypes.string,
  orderIn: PropTypes.string,
  pageSize: PropTypes.number,
  createNewIcon: PropTypes.string,
  createNewElement: PropTypes.element,
  onCreateNewItem: PropTypes.func,
};

export default function DataList({
  data,
  headers,
  sectionTitle,
  createNewLabel,
  primaryKey,
  filterColumn,
  optionItems = [],
  selectionOptions = [],
  rowData,
  emptyIcon,
  loading = false,
  filterOptions,
  pageSize = 10,
  avatarKey,
  onCreateNewItem,
  createNewElement,
  orderIn = "desc",
  createNewIcon = "add"
}) {
  const el = document.getElementsByClassName("simplebar-placeholder");
  if (el && el[0] && el[0].style) {
    el[0].style.display = "none";
  }
  const appStyle = coreStyles(),
    selectable = selectionOptions && selectionOptions.length > 0;
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState(orderIn);

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(filterColumn);

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[primaryKey]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleSelected = (column) => {
    const selectedIndex = selected.indexOf(column);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, column);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const filteredData = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName,
    filterColumn
  );

  const isSearchNotFound = filteredData.length === 0;

  return (
    <Page>
      <Container
        maxWidth="xl"
        sx={{
          paddingLeft: `${
            sectionTitle && createNewLabel ? 24 : 0
          }px !important;`,
        }}
      >
        {sectionTitle && (createNewElement || createNewLabel) && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              {sectionTitle}
            </Typography>
            {((createNewLabel && createNewLabel.length > 0) ||
              createNewElement) &&  (createNewElement || 
              <Button
                variant="contained"
                onClick={() => onCreateNewItem()}
                className={appStyle.actionButon}
                startIcon={
                  <Icon
                    children={createNewIcon}
                    className={appStyle.actionButonIcon}
                  />
                }
              >
                {createNewLabel}
              </Button>
            )}
          </Stack>
        )}

        <Card sx={{ py: selected.length > 0 ? 0 : 3 }}>
          {loading && <LinearProgress />}
          {selectionOptions && selectionOptions.length > 0 && (
            <ListToolbar
              selected={selected}
              filterName={filterName}
              selectionOptions={selectionOptions}
              filterOptions={filterOptions}
              onOptionSelection={() => setSelected([])}
              label={sectionTitle.toLowerCase()}
              onFilterName={handleFilterByName}
            />
          )}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {headers && headers.length > 0 && (
                  <ListHeader
                    className={!selectable ? appStyle.paddingRow : null}
                    order={order}
                    orderBy={orderBy}
                    headLabel={headers}
                    rowCount={data.length}
                    selectable={selectable}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                )}

                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((rowItem, index) => {
                      const isItemSelected =
                        selected.indexOf(rowItem[primaryKey]) !== -1;
                      const rowProps = rowData(rowItem);
                      const moreOptions = optionItems.filter(
                        (opt) =>
                          (rowProps.exclude || []).indexOf(opt.value) === -1
                      );
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role={selectable ? "checkbox" : "p"}
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {selectable && (
                            <TableCell
                              padding="checkbox"
                              className={appStyle.checkboxPadding}
                            >
                              <Checkbox
                                checked={isItemSelected}
                                onChange={() =>
                                  handleSelected(rowItem[primaryKey])
                                }
                              />
                            </TableCell>
                          )}

                          {rowProps.entries
                            .filter((data) => data.value != null)
                            .map((cell, index) => {
                              return cell.avatar ? (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                >
                                  <Stack className={appStyle.avatarMargin}>
                                    <Avatar alt={cell.value} src={cell.value}>
                                      {getAvatarLabel(
                                        rowItem,
                                        avatarKey || "name"
                                      )}
                                    </Avatar>
                                  </Stack>
                                </TableCell>
                              ) : (
                                <TableCell
                                  className={
                                    !selectable && index === 0
                                      ? appStyle.paddingRow
                                      : null
                                  }
                                  align={cell.align || "left"}
                                  sx={{ minWidth: `${cell.width || 100}px` }}
                                >
                                  <Typography variant="body2">
                                    {cell.isLabel ? (
                                      <Label variant="ghost" color={cell.color}>
                                        {cell.value}
                                      </Label>
                                    ) : (
                                      cell.value
                                    )}
                                  </Typography>
                                </TableCell>
                              );
                            })}

                          {moreOptions && moreOptions.length > 0 && (
                            <TableCell align="right">
                              <MoreMenu
                                options={moreOptions}
                                selected={[rowItem[primaryKey]]}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>

                {isSearchNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <SearchNotFound
                          icon={emptyIcon}
                          searchQuery={filterName}
                          label={sectionTitle ? sectionTitle.toLowerCase() : ""}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {data && data.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
    </Page>
  );
}
