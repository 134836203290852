import {
  Checkbox,
  Typography,
  FormControlLabel,
  Grid,
  Link,
  Card,
  Button,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { coreStyles } from "../theme/style";
import AppRoutes from "../routing/routes";
import { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import {
  acceptedMimes,
  isError,
  maxFileSize,
  navigate,
  subscribe,
  uploadFile,
  UserRoles,
  validateEmail,
  validateFields,
  validateName,
  validatePassword,
  validatePhone,
} from "../utils/utils";
import Status from "./component/status";
import Logo from "./component/logo";
import EasyTextEdit from "./component/text-edit";

const userRef = {
  user_name: "",
  user_email: "",
  user_phone: "",
  user_password: "",
  user_role: UserRoles.merchant,
  user_auto_disburse: false,
  user_2fa_enabled: false,
};

const validationRef = {
  user_name: { error: true, touched: false },
  user_email: { error: true, touched: false },
  user_phone: { error: true, touched: false },
  user_password: { error: true, touched: false },
};

const labelRef = "Register";

const AuthRegister = () => {
  const [user, setUser] = useState(userRef);
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const appStyle = coreStyles();
  const [onProcessing, setOnProcessing] = useState(false);
  const [btnLabel, setLabel] = useState(labelRef);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [progress, setProgress] = useState(0);
  const [level, setLevel] = useState("");
  const [status, setStatus] = useState({ error: false, message: null });

  const handleSubmit = (event) => {
    event.preventDefault();
    uploadFile(
      fileToUpload,
      (response) => {
        user.user_national_id = response;
        setUser({ ...user });
        subscribe(
          "user/create",
          user,
          (error, result) => {
            setLabel(labelRef);
            setStatus({
              ...{
                error: error != null,
                message: result ? result.message : error.message,
              },
            });
          },
          (loading) => {
            setLabel("");
            setOnProcessing(loading);
          }
        );
      },
      (progress) => {
        setLabel("");
        setOnProcessing(true);
        setProgress(progress);
      }
    );
  };

  const onValueChange = (value, tag) => {
    user[tag] = value;
    if (tag.includes("_password")) {
      const strn = validatePassword(value);
      validation.user_password = { error: strn.level < 75, touched: true };
      setLevel(strn);
    }

    if (tag.includes("_name")) {
      validation.user_name = { error: !validateName(value), touched: true };
    }
    if (tag.includes("_email")) {
      validation.user_email = { error: !validateEmail(value), touched: true };
    }

    if (tag.includes("_phone")) {
      validation.user_phone = { error: !validatePhone(value), touched: true };
    }
    setUser({ ...user });
    setValidation({ ...validation });
    setValidated(validateFields(validation));
  };

  const handleChangeStatus = (file, status) => {
    setFileToUpload(status === "done" ? file.file : null);
  };

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid item xs={12} md={7}>
        <Card className={appStyle.loginCard}>
          <Typography
            variant="h2"
            color="primary.darker"
            align="center"
            mb={4}
            className={appStyle.title}
          >
            Create account
          </Typography>
          <Typography
            align="center"
            variant="body1"
            mb={4}
            className={appStyle.sub_title}
          >
            You're moments away from launching a blazing easy payment solution.
          </Typography>

          <Grid container spacing={2}>
            {status && status.message && (
              <Grid item xs={12}>
                <Status
                  error={status.error}
                  timeout={5000}
                  message={status.message}
                  onClose={() => {
                    if (status.error) {
                      setStatus({ ...{ message: null } });
                    } else {
                      navigate(AppRoutes.login, true);
                    }
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <EasyTextEdit
                placeholder="Full name"
                type="text"
                id="user_name"
                errorText="Invalid Person name"
                error={isError(validation.user_name)}
                disabled={onProcessing}
                value={user.user_name}
                icon="person"
                onChange={onValueChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <EasyTextEdit
                placeholder="Email address"
                type="email"
                id="user_email"
                errorText="Invalid email address"
                error={isError(validation.user_email)}
                disabled={onProcessing}
                value={user.user_email}
                icon="alternate_email"
                onChange={onValueChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <EasyTextEdit
                placeholder="Phone number"
                type="phone"
                id="user_phone"
                errorText="Invalid phone number"
                error={isError(validation.user_phone)}
                disabled={onProcessing}
                value={user.user_phone}
                icon="phone"
                onChange={onValueChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <EasyTextEdit
                placeholder="Password"
                type="password"
                id="user_password"
                errorText={`Password is ${level.label}`}
                error={isError(validation.user_password)}
                disabled={onProcessing}
                value={user.user_password}
                icon="password"
                password={true}
                onChange={onValueChange}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              {progress > 0 && progress <= 100 && (
                <LinearProgress
                  sx={{ mb: 3 }}
                  value={progress}
                  variant="determinate"
                />
              )}
              <Dropzone
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                maxSizeBytes={maxFileSize}
                disabled={onProcessing}
                accept={acceptedMimes}
                submitButtonDisabled={true}
                submitButtonContent={null}
                inputContent={(files, extra) => {
                  return extra.reject
                    ? "Only images or PDF are allowed"
                    : "Click  or Drag & Drop to add your Identification Document (PDF or Image)";
                }}
                styles={{
                  dropzone: { minHeight: 100, maxHeight: 150 },
                  dropzoneReject: {
                    borderColor: "red",
                    backgroundColor: "#DAA",
                  },
                  inputLabel: (files, extra) =>
                    extra.reject
                      ? { color: "red" }
                      : { color: "black", fontWeight: 500, fontSize: "1em" },
                }}
              />
              <Typography color="text.disabled" mt={1.8} variant="body2">
                * For Individuals add <strong>National ID / Passport</strong><br/>
                * For Businesses add <strong>Business License & TIN</strong> as a Single Document
              </Typography>
            </Grid>

            <Grid item xs={12} sm={7} mt={4}>
              <FormControlLabel
                checked={user.user_2fa_enabled}
                onChange={(event) =>
                  onValueChange(event.target.checked, "user_2fa_enabled")
                }
                control={<Checkbox />}
                label={
                  <Typography className={appStyle.inputs} variant="body2">
                    Enable two factor authentication <strong>(2FA)</strong>
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} sm={5} mb={5}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                disabled={!(!onProcessing && fileToUpload && validated)}
                className={appStyle.loginButton}
              >
                {onProcessing && <CircularProgress color="primary" />}
                {btnLabel}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <p align="center">
                <Link
                  href={AppRoutes.login}
                  variant="body2"
                  className={appStyle.links}
                >
                  &nbsp;&nbsp;Go back to Login
                </Link>
              </p>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AuthRegister;
