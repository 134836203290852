import {
  FormControl,
  CircularProgress,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Typography,
  Button,
  Card,
  Grid,
  Box,
  Icon,
} from "@mui/material";
import { coreStyles } from "../theme/style";
import { useState, useEffect } from "react";
import {
  guardRoute,
  logAuthenticated,
  subscribe,
} from "../utils/utils";
import Logo from "./component/logo";
import Status from "./component/status";
const label = "Authenticate";
const Auth2FaAuth = () => {
  guardRoute();
  const appStyle = coreStyles();
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");
  const [onAuthing, setOnAuthing] = useState(false);
  const [btnLabel, setLabel] = useState(label);
  const [status, setStatus] = useState({ error: false, message: null });

  const handleSubmit = (event) => {
    event.preventDefault();
    subscribe(
      "auth/validate2fa",
      { authToken: otp },
      (error, result) => {
        if (error && !result) {
          setLabel(label);
          setStatus({
            ...{
              error: error != null,
              message: result ? result.message : error.message,
            },
          });
        }
        if (!error && result) {
          logAuthenticated();
        }
      },
      (loading) => {
        setLabel("");
        setOnAuthing(loading);
      }
    );
  };

  const onValueChanged = (value) => {
    setOtp(value);
    setError("");
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, [otp, btnLabel, error, onAuthing]);

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid item xs={11} md={5}>
        <Card className={appStyle.loginCard}>
          <Typography
            variant="h2"
            align="center"
            color="primary.darker"
            mb={4}
            className={appStyle.title}
          >
            Two-Factor Authentication
          </Typography>
          <Grid container justifyContent="center">
            <Grid item mb={2}>
              <Typography
                variant="body1"
                align="center"
                className={appStyle.sub_title}
              >
                Please enter the code generated by your mobile application or
                security key.
              </Typography>
            </Grid>
          </Grid>
          <Box noValidate>
            {status && status.message && (
              <Grid item xs={12} mb={4}>
                <Status
                  error={status.error}
                  timeout={5000}
                  message={status.message}
                  onClose={() => {
                    if (status.error) {
                      setStatus({ ...{ message: null } });
                    }
                  }}
                />
              </Grid>
            )}
            <FormControl variant="filled" fullWidth sx={{ mt: 4, mb: 4 }}>
              <OutlinedInput
                placeholder="Authentication code"
                type="text"
                className={appStyle.inputs}
                onChange={(event) => onValueChanged(event.currentTarget.value)}
                inputlabelprops={{ shrink: true }}
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="center">
                      <Icon children="pin" className={appStyle.input_icons} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={otp.length < 6 || onAuthing}
              className={appStyle.loginButton}
            >
              {onAuthing && <CircularProgress color="primary" />}
              {btnLabel}
            </Button>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Auth2FaAuth;
