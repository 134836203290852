import {
  FormControl,
  Grid,
  ButtonGroup,
  Button,
  Typography,
  Card,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
  Icon,
  FormControlLabel,
  MenuItem,
  Checkbox,
  Select,
  Box,
  Avatar,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import {
  validatePassword,
  isError,
  getActiveUser,
  validateFields,
  cleanObject,
  showSnack,
  UserRoles,
  formatDate,
  getUserStatus,
  logout,
  subscribe,
  guardRoute,
  getLogIcon,
  watchLiveData,
  updateAddSessionValue,
  fCurrency,
} from "../utils/utils";
import Page from "./component/page";
import { useState, useEffect, Fragment } from "react";
import { coreStyles } from "../theme/style";
import Label from "./component/label";
import EasyTextEdit from "./component/text-edit";
import DataList from "./component/@list/list";

const profileKey = "profile";

const authKey = "auth";

const activityKey = "activities";

const systemKey = "system";

var sectionOptions = [
  { key: profileKey, title: "Profile" },
  { key: authKey, title: "Security" },
  { key: activityKey, title: "Activities" },
  { key: systemKey, title: "System" },
];

const validationRef = {
  password: { error: true, touched: false },
  password_conf: { error: true, touched: false },
};

const passwordRef = {
  password: "",
  password_conf: "",
  password_old: "",
};

const userRef = {
  user_name: "",
  user_email: "",
  user_phone: "",
  user_role: -1,
  user_2fa_enabled: false,
  user_ref_name: "",
  user_ref_id: "",
  user_app: "",
  user_auto_disburse: false
};

var roles = [
  { role: UserRoles.admin, label: "System Admin" },
  { role: UserRoles.merchant, label: "Merchant" },
  { role: UserRoles.accountant, label: "Accountant" },
  { role: UserRoles.sales, label: "Sale's Person" },
];

const _systemProps = { password: "", path: null };

const btnChangeLabel = "Change Password";

const AccountSetting = () => {
  guardRoute();
  const appStyle = coreStyles();
  const loggedUser = getActiveUser();
  sectionOptions = sectionOptions.filter(
    (option) =>
      option.key !==
      (loggedUser.role === UserRoles.admin ? profileKey : systemKey)
  );
  const [user, setUser] = useState(userRef);
  const [systemProps, setSystemProps] = useState(_systemProps);
  const [validation, setValidation] = useState(validationRef);
  const [passwords, setPasswords] = useState(passwordRef);
  const [section, setSection] = useState(sectionOptions[0]);
  const [validated, setValidated] = useState(false);
  const [onProcessing, setOnProcessing] = useState(false);
  const [passwordVisible, showPassword] = useState(false);
  const [level, setLevel] = useState("");
  const [updateType, setUpdateType] = useState(-1);
  const [label, setLabel] = useState(btnChangeLabel);
  const [account, setAccount] = useState(null);
  const [system, setSystem] = useState([]);
  const [logList, setLogList] = useState([]);
  useEffect(() => {
    fetchUser(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (section.key === systemKey) {
      fetchStatus();
      watchLiveData("system", (_) => fetchStatus());
    }
  }, [section]);

  const fetchStatus = () => {
    subscribe(`system/status`, null, (_, res) => setSystem(res));
  };

  const fetchUser = (showLoading) => {
    const { id } = getActiveUser();
    Promise.all([
      subscribe(`user/find?id=${id}`, null, null, (loading) =>
        setOnProcessing(loading && showLoading)
      ),
      subscribe(`transfer/account`, null, null, (loading) =>
        setOnProcessing(loading && showLoading)
      ),
      subscribe(
        `logs/find?id=${id}&type=user`,
        null,
        (_, res) => setLogList(res),
        (loading) => setOnProcessing(loading && showLoading)
      ),
    ]).then((response) => {
      if (response[0] && response[0][0]) {
        setUser({...user, ...response[0][0] });
        updateAddSessionValue("auto", response[0][0].user_auto_disburse);
      }
      if (response[1] && response[1]._id) {
        setAccount(response[1]);
      }
    });
  };

  const updateUser = () => {
    setUpdateType(0);
    subscribe(
      "user/update",
      cleanObject(user),
      (error, result) => {
        fetchUser(true);
        setUpdateType(-1);
        showSnack(
          String(error ? error.message : result.message).replace(
            "User",
            "Profile"
          )
        );
      },
      (loading) => setOnProcessing(loading)
    );
  };

  const onValueChanged = (value, tag) => {
    if (tag.includes("password")) {
      const tagStr = String(tag);
      passwords[tag] = value;
      const strn = validatePassword(value);
      const values = { error: strn.level < 75, touched: true };

      if (tagStr.endsWith("_conf")) {
        validation.password_conf = values;
      }

      if (tagStr === "password") {
        setLevel(strn);
        validation.password = values;
      }
    } else {
      user[tag] = value;
    }

    setUser({ ...user });
    setValidation({ ...validation });
    setPasswords({ ...passwords });
    setValidated(
      validateFields(validation) &&
        passwords.password_conf === passwords.password &&
        passwords.password_old.length >= 6
    );

    if (tag.includes("2fa") || tag.includes("auto")) {
      updateUser();
    }
  };

  const handleSubmit = () => {
    setUpdateType(1);
    subscribe(
      "auth/changePassword",
      cleanObject(passwords),
      (error, result) => {
        setUpdateType(-1);
        setLabel(btnChangeLabel);
        showSnack(String(error ? error.message : result.message));
        if (!error) {
          setTimeout(() => logout(), 3000);
        }
      },
      (loading) => {
        setLabel("");
        setOnProcessing(loading);
      }
    );
  };

  const handleSystemBackup = (path) => {
    showPassword(path ? true : false);
    if (path) {
      setSystemProps({ ...systemProps, path: path });
    }
  };

  const handleSystemAction = () => {
    showPassword(false);
    setSystemProps({ ..._systemProps });
    subscribe(
      `system/${systemProps.path}`,
      { password: systemProps.password },
      (_, res) => showSnack((_ || res).message)
    );
  };

  return (
    <Page title="Account Setting">
      <Grid
        container
        sx={{
          paddingLeft: 4,
          paddingRight: section.key !== activityKey ? 4 : 0,
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h2" className={appStyle.userName}>
            {`${loggedUser.name}`}
          </Typography>
          <Typography variant="body2" color="text.disabled">
            {loggedUser.email} &nbsp;&nbsp;
            <strong>
              {loggedUser.role === UserRoles.merchant
                ? `(Merchant ID #${loggedUser.user_id})`
                : ``}
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={12} mt={4} mb={4}>
          <ButtonGroup size="large" aria-label="large button group">
            {sectionOptions.map((sect) => {
              return (
                <Button
                  key={sect.key}
                  className={
                    section.key === sect.key ? appStyle.selectedGrouped : null
                  }
                  onClick={() => setSection(sect)}
                >
                  {sect.title}
                </Button>
              );
            })}
          </ButtonGroup>
        </Grid>

        {section.key !== activityKey && (
          <Card sx={{ p: 7, minWidth: "100%" }}>
            {section.key === authKey && (
              <Grid container spacing={10}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" mb={2}>
                    Two Factor Authentication
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mr: 3 }}
                    align="justify"
                    className={appStyle.description}
                  >
                    Enable two-factor authentication to keep your account safe.
                    You'll be asked to enter your password as well as a randomly
                    generated access code each time you log in.
                  </Typography>

                  <Grid item xs={12} mt={4}>
                    <FormControlLabel
                      checked={user.user_2fa_enabled}
                      onChange={(event) =>
                        onValueChanged(event.target.checked, "user_2fa_enabled")
                      }
                      control={<Checkbox />}
                      label={
                        <Typography className={appStyle.inputs} variant="body2">
                          Enable two factor authentication{" "}
                          <strong>(2FA)</strong>
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4">Change Password</Typography>
                  <Grid container mt={4} spacing={2}>
                    <Grid item xs={12}>
                      <EasyTextEdit
                        placeholder="Current Password"
                        type="password"
                        id="password_old"
                        disabled={onProcessing}
                        value={passwords.password_old}
                        icon="password"
                        password={true}
                        onChange={onValueChanged}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <EasyTextEdit
                        placeholder="New Password"
                        type="password"
                        id="password"
                        errorText={`Password is ${level.label}`}
                        error={isError(validation.password)}
                        disabled={onProcessing}
                        value={passwords.password}
                        icon="password"
                        password={true}
                        onChange={onValueChanged}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <EasyTextEdit
                        placeholder="Confirm Password"
                        type="password"
                        id="password_conf"
                        errorText={`Doesn't match your password`}
                        error={isError(validation.password_conf)}
                        disabled={onProcessing}
                        value={passwords.password_conf}
                        icon="password"
                        password={true}
                        onChange={onValueChanged}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                        disabled={!(validated && !onProcessing)}
                        className={appStyle.loginButton}
                      >
                        {onProcessing && updateType === 1 && (
                          <CircularProgress color="primary" />
                        )}
                        {label}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {section.key === profileKey && (
              <Grid container spacing={10}>
                <Grid item xs={12} md={7}>
                  <Typography variant="h4" mb={2}>
                    Active Disbursement Account
                  </Typography>

                  {account == null && (
                    <Typography
                      variant="h1"
                      align="center"
                      color="text.disabled"
                      mt={15}
                    >
                      N/A
                    </Typography>
                  )}

                  {account != null && (
                    <Grid container mt={4} spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1" color="text.disabled">
                          Last updated on{" "}
                          {formatDate(account.trans_last_update, "LL")}
                          {user.user_auto_disburse && (
                            <strong>
                              &nbsp;&nbsp;&nbsp; (Min threshold&nbsp;&nbsp;
                              {fCurrency(account.trans_min_threshold)})
                            </strong>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant="filled" fullWidth>
                          <OutlinedInput
                            required
                            placeholder="Account Name"
                            value={account.trans_account_name}
                            disabled={true}
                            className={appStyle.inputs}
                            inputlabelprops={{ shrink: true }}
                            startAdornment={
                              <InputAdornment position="end">
                                <IconButton edge="center">
                                  <Icon
                                    children="account_circle"
                                    className={appStyle.input_icons}
                                  />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl variant="filled" fullWidth>
                          <OutlinedInput
                            required
                            placeholder={
                              account.trans_type
                                ? "Account Number"
                                : "Phone Number"
                            }
                            value={account.trans_account}
                            disabled={true}
                            className={appStyle.inputs}
                            inputlabelprops={{ shrink: true }}
                            startAdornment={
                              <InputAdornment position="end">
                                <IconButton edge="center">
                                  <Icon
                                    children={
                                      account.trans_type
                                        ? "account_balance_wallet"
                                        : "call"
                                    }
                                    className={appStyle.input_icons}
                                  />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl variant="filled" fullWidth>
                          <OutlinedInput
                            placeholder={
                              account.trans_type
                                ? "Bank Name"
                                : "Mobile Wallet Name"
                            }
                            type="text"
                            value={account.trans_account_org}
                            disabled={true}
                            className={appStyle.inputs}
                            inputlabelprops={{ shrink: true }}
                            startAdornment={
                              <InputAdornment position="end">
                                <IconButton edge="center">
                                  <Icon
                                    children={
                                      account.trans_type
                                        ? "account_balance"
                                        : "account_balance_wallet"
                                    }
                                    className={appStyle.input_icons}
                                  />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Select
                            className={appStyle.inputs}
                            inputlabelprops={{ shrink: true }}
                            value={account.trans_currency}
                            disabled={true}
                            startAdornment={
                              <InputAdornment position="end">
                                <IconButton edge="center">
                                  <Icon
                                    children="currency_exchange"
                                    className={appStyle.input_icons}
                                  />
                                </IconButton>
                              </InputAdornment>
                            }
                          >
                            {[
                              { value: "TZS", label: "Tanzanian Shillings" },
                            ].map((curr) => {
                              return (
                                <MenuItem
                                  value={curr.value}
                                  className={appStyle.inputs}
                                >
                                  &nbsp;&nbsp;&nbsp;{curr.label}
                                  <br />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          checked={true}
                          disabled={true}
                          control={<Checkbox />}
                          label={
                            <Typography
                              className={appStyle.inputs}
                              variant="body2"
                            >
                              {account.trans_type
                                ? "Bank Transfers"
                                : "Mobile wallet transfers"}
                            </Typography>
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          checked={user.user_auto_disburse}
                          onChange={(event) =>
                            onValueChanged(
                              event.target.checked,
                              "user_auto_disburse"
                            )
                          }
                          control={<Checkbox />}
                          label={
                            <Typography
                              className={appStyle.inputs}
                              variant="body2"
                            >
                              {user.user_auto_disburse
                                ? "Disable Auto Disbursement"
                                : "Enable Auto Disbursement"}
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12} md={5}>
                  <Typography variant="h4">Profile Information</Typography>
                  <Grid container mt={4} spacing={2}>
                    <Grid container direction="row" px={2} mt={2}>
                      <Typography variant="body1" color="text.disabled">
                        Joined {user.user_date}
                      </Typography>
                      <Box px={5} ml={2}>
                        <Label
                          variant="outlined"
                          color={getUserStatus(user.user_status).color}
                        >
                          {getUserStatus(user.user_status).value}
                        </Label>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="filled" fullWidth>
                        <OutlinedInput
                          required
                          placeholder="Person name"
                          value={user.user_name}
                          disabled={true}
                          className={appStyle.inputs}
                          inputlabelprops={{ shrink: true }}
                          startAdornment={
                            <InputAdornment position="end">
                              <IconButton edge="center">
                                <Icon
                                  children="person"
                                  className={appStyle.input_icons}
                                />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <OutlinedInput
                          required
                          placeholder="Email Address"
                          value={user.user_email}
                          disabled={true}
                          className={appStyle.inputs}
                          inputlabelprops={{ shrink: true }}
                          startAdornment={
                            <InputAdornment position="end">
                              <IconButton edge="center">
                                <Icon
                                  children="alternate_email"
                                  className={appStyle.input_icons}
                                />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Select
                          className={appStyle.inputs}
                          inputlabelprops={{ shrink: true }}
                          disabled={true}
                          value={user.user_role}
                          startAdornment={
                            <InputAdornment position="end">
                              <IconButton edge="center">
                                <Icon
                                  children="admin_panel_settings"
                                  className={appStyle.input_icons}
                                />
                              </IconButton>
                            </InputAdornment>
                          }
                        >
                          {roles.map((role) => {
                            return (
                              <MenuItem
                                value={role.role * 1}
                                className={appStyle.inputs}
                              >
                                &nbsp;&nbsp;&nbsp;{role.label}
                                <br />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {user.user_ref_name.length > 0 && (
                      <Grid item xs={12}>
                        <FormControl variant="filled" fullWidth>
                          <OutlinedInput
                            required
                            placeholder="Manager's name"
                            value={user.user_ref_name}
                            disabled={true}
                            className={appStyle.inputs}
                            inputlabelprops={{ shrink: true }}
                            startAdornment={
                              <InputAdornment position="end">
                                <IconButton edge="center">
                                  <Icon
                                    children="person"
                                    className={appStyle.input_icons}
                                  />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}

            {section.key === systemKey && (
              <Grid container spacing={10}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" mb={2}>
                    System Statuses
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mr: 3 }}
                    align="justify"
                    className={appStyle.description}
                  >
                    When was the last time system was backed-up, restored or
                    restarted
                  </Typography>

                  {system &&
                    system.length > 0 &&
                    system.map((data) => {
                      return (
                        <Fragment>
                          <Typography
                            variant="body1"
                            sx={{ mr: 3, mt: 3 }}
                            align="justify"
                            className={appStyle.description}
                          >
                            {data.label}
                          </Typography>

                          <Typography
                            variant="body1"
                            sx={{ mr: 3, mt: 1, mb: 2 }}
                            align="justify"
                            color="text.disabled"
                            className={appStyle.description}
                          >
                            {formatDate(data.value, "LLLL")}
                          </Typography>
                        </Fragment>
                      );
                    })}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" mb={2}>
                    {`System Backup, Restore & Restart`}
                  </Typography>

                  <Typography
                    variant="body1"
                    sx={{ mr: 3 }}
                    align="justify"
                    className={appStyle.description}
                  >
                    Restore backed up copy of system's Database, Backup the
                    current state of the system's Database or Restart the entire
                    system. Be advised that when restoring the action is
                    irrevisible.
                  </Typography>

                  <Grid container spacing={6} direction="row">
                    <Grid item xs={12} md={6} mt={4}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => handleSystemBackup("backup")}
                        className={appStyle.loginButton}
                      >
                        System Backup
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} mt={4}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => handleSystemBackup("restore")}
                        className={appStyle.loginButton}
                      >
                        System Restore
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} mt={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="error"
                      onClick={() => handleSystemBackup("restart")}
                      className={appStyle.loginButton}
                    >
                      System Restart
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Card>
        )}

        {section.key === activityKey && (
          <Grid item xs={12} sm={12} md={12}>
            {logList && logList.length > 0 && (
              <Typography variant="h4" mb={2}>
                Recent activities
              </Typography>
            )}

            {logList && logList.length === 0 && (
              <Grid item xs={12} textAlign="center" sx={{ py: 3.8 }}>
                <Icon
                  children="query_stats"
                  sx={{ mt: 4, fontSize: "6rem !important" }}
                />
                <Typography variant="h4" color="primary.dark" mt={2}>
                  No recent activities
                </Typography>
              </Grid>
            )}

            {logList.length > 0 && (
              <DataList
                data={logList}
                primaryKey="_id"
                filterColumn="logs_timestamp"
                emptyIcon="apps"
                loading={false}
                headers={[
                  { id: "_" },
                  { id: "tmstmp", label: "Timestamp   ", alignRight: false },
                  { id: "from", label: "Source", alignRight: false },
                  {
                    id: "desc",
                    label: "What was done",
                    alignRight: false,
                  },
                  {
                    id: "action",
                    label: "Performed",
                    alignRight: false,
                  },
                ]}
                pageSize={5}
                rowData={(data) => {
                  const props = getLogIcon(data.logs_event);
                  return {
                    exclude: [],
                    entries: [
                      {
                        value: (
                          <Avatar
                            variant="circle"
                            sx={{ bgcolor: props.color, mr: 3 }}
                          >
                            <Icon children={props.icon} />
                          </Avatar>
                        ),
                      },
                      {
                        value: formatDate(
                          data.logs_timestamp,
                          "MMM DD,YY hh:mm A"
                        ),
                        width: 160,
                      },
                      { value: data.logs_key || "-" },
                      { value: data.logs_desc },
                      { value: data.logs_event.toUpperCase(), width: 130 },
                    ],
                  };
                }}
              />
            )}
          </Grid>
        )}
      </Grid>

      {passwordVisible && (
        <Dialog
          open={passwordVisible}
          maxWidth="lg"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ px: 4, mt: 2 }} id="alert-dialog-title">
            Enter your password to authorize this action
          </DialogTitle>
          <DialogContent>
            <DialogContentText xs={{ mt: 5 }} id="alert-dialog-description">
              <Grid container spacing={4} sx={{ py: 3, px: 3 }}>
                <Grid item xs={12} sm={12}>
                  <EasyTextEdit
                    placeholder="Account password"
                    type="text"
                    id="password"
                    password={true}
                    errorText=""
                    error={false}
                    value={systemProps.password}
                    icon="password"
                    sx={{ width: 400 }}
                    onChange={(value, key) =>
                      setSystemProps({ ...systemProps, password: value })
                    }
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: 3, mr: 5 }}>
            <Button onClick={() => showPassword(false)}>DISMISS</Button>
            <Button
              onClick={() => handleSystemAction()}
              disabled={systemProps && systemProps.password.length === 0}
            >
              CONTINUE
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Page>
  );
};

export default AccountSetting;
