import makeStyles from "@mui/styles/makeStyles";
import { filesUrl } from "../utils/utils";

export const coreStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    margin: "0px !important",
    padding: `${theme.spacing(2)} 0px 0px 0px !important`,
    backgroundImage: `url('${filesUrl}easypay-background.svg')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  title: {
    fontWeight: "900 !important",
  },
  loginButton: {
    fontSize: "1.1rem",
    marginTop: `${theme.spacing(2.5)} !important`,
    padding: theme.spacing(1.5),
  },
  innerButton: {
    fontSize: "1.1rem",
    marginTop: `${theme.spacing(6)} !important`,
    padding: theme.spacing(1.5),
  },
  payButton: {
    fontSize: "1.1rem",
    marginTop: `${theme.spacing(2)} !important`,
    padding: theme.spacing(1.5),
  },
  circular: {
    fontSize: "0.4em !important",
  },
  menuIcon: {
    fontSize: "2rem !important",
  },
  actionButon: {
    fontSize: "1rem !important",
    padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
  },
  actionButonIcon: {
    fontSize: "1.8rem !important",
  },
  loginCard: {
    marginBlock: theme.spacing(4),
    padding: "8% !important",
  },
  loginWrapper: {
    margin: "5% !important",
  },
  links: {
    textDecoration: "none !important",
    margin: `${theme.spacing(2)} 0px !important`,
    fontSize: "1.1em !important",
    fontWeight: "700 !important",
    alignItems: "center !important",
  },
  link_span: {
    fontSize: "1.1em !important",
    textDecoration: "none !important",
    alignItems: "center !important",
  },
  sub_title: {
    textDecoration: "none !important",
    padding: "0 10% !important",
  },
  textPadding: {
    padding: "0 10% !important",
  },
  inputs: {
    padding: "0.1em !important",
    width: "100% !important",
  },
  inputsNoPadding: {
    fontSize: "1.2em !important",
    width: "100% !important",
  },
  input_icons: {
    fontSize: "1.2em !important",
  },
  hide: {
    display: "none !important",
  },
  show: {
    display: "flex column !important",
  },
  icon_standalone: {
    fontSize: "6rem !important",
  },
  icon_empty: {
    fontSize: "10rem !important",
  },
  avatarMargin: {
    marginTop: `${theme.spacing(1)} !important`,
    marginLeft: `${theme.spacing(1)} !important`,
  },
  paddingRow: {
    paddingLeft: "2.8% !important",
  },
  checkboxPadding: {
    padding: `0px 0px 0px ${theme.spacing(2)} !important`,
  },
  snackbar: {
    fontWeight: 400,
    fontSize: "1.1em !important",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} !important`,
  },
  selectedGrouped: {
    backgroundColor: `${theme.palette.primary.lighter} !important`,
  },
  balance: {
    fontWeight: "400 !important",
  },
  tableText: {
    fontSize: "10px !important",
    lineHeight: "0.25px !important",
    fontWeight: "normal !important",
    letterSpacing: "0.01em !important",
  },
  userName: {
    fontWeight: 200,
  },
  description: {
    fontWeight: "1.2em !important",
  },
  zeroPadding: {
    
  },
}));
