import {
  Grid,
  ButtonGroup,
  Button,
  Box,
  IconButton,
  Icon,
} from "@mui/material";
import {
  guardRoute,
  getPaymentStatus,
  fCurrency,
  maskString,
  fNumber,
  formatDate,
  fCurrencyShort,
  getParams,
  copyText,
  showSnack,
  navigate,
  subscribe,
  liveData,
} from "../utils/utils";
import Page from "./component/page";
import { useState, useEffect } from "react";
import SummaryCard from "./component/summary-card";
import DataList from "./component/@list/list";
import { coreStyles } from "../theme/style";
import AppRoutes from "../routing/routes";
import { MoreMenu } from "./component/@list";

const getHeader = (normalPayment) => {
  const id = getParams().id;
  const header = [
    { id: "trans_timestamp", label: "Trans DateTime", alignRight: false },
    { id: "trans_reference_id", label: "Trans ID", alignRight: false },
    {
      id: normalPayment ? "trans_client_id" : "trans_max_purchase",
      label: normalPayment ? "Client ID" : "Trnx Type",
      alignRight: false,
    },
    { id: "trans_amount", label: "Amount", alignRight: false },
    {
      id: normalPayment ? (id ? "trans_instalment" : "trans_app_name") : "paid",
      label: normalPayment ? (id ? "Instalment" : "Application") : "Paid",
      alignRight: false,
    },
    { id: "trans_channel_name", label: "Channel", alignRight: false },
    { id: "trans_status", label: "Status", alignRight: false },
    { id: "_", alignRight: true },
  ];
  return header;
};

var liveFilters = [];

const btnGroupOptions = [
  { key: "Daily", title: "Today's Transactions", range: "day" },
  { key: "Weekly", title: "Weekly Transactions", range: "week" },
  { key: "Monthly", title: "Monthly Transactions", range: "month" },
];

const linkable = "https://my.easypay.co.tz/transaction/payout?id=";

const RevenueSummary = () => {
  guardRoute();
  const [transList, setTransList] = useState([]);
  const [summaryList, setSummary] = useState([]);
  const [selectedBtn, setSelectedBtn] = useState(btnGroupOptions[0]);
  const [loading, onLoading] = useState(false);
  const [normalPayment, setPaymentType] = useState(true);
  const [filterKey, setFilterkey] = useState("trans_timestamp");
  const [id] = useState(getParams().id);
  const appStyle = coreStyles();

  const filterOptions = [
    { value: "trans_client_id", label: "Phone Number", onClick: setFilterkey },
    { value: "trans_reference_id", label: "Transaction ID", onClick: setFilterkey },
    { value: "trans_channel", label: "Channel", onClick: setFilterkey },
    { value: "trans_app_name", label: "Business (App)", onClick: setFilterkey },
    { value: "trans_status", label: "Status", onClick: setFilterkey },
  ];

  const paymentTypes = [
    {
      icon: "paid",
      value: "normal",
      label: "Direct Payment",
      onClick: () => setPaymentType(true),
    },
    {
      value: "instalment",
      icon: "layers",
      label: "Instalments /Tickets",
      onClick: () => setPaymentType(false),
    },
  ];

  const executeFetch = (summaryOnly = false, refresh = true) => {
    Promise.all([
      subscribe(
        `transaction/summary`,
        id,
        (_, result) => {
          if (result) setSummary(result);
        },
        (loading) => onLoading(refresh ? loading : false)
      ),
      subscribe(`apps/find`, id, (_, result) => {
        liveFilters = result.map((app) => app._id);
      }),
      summaryOnly
        ? null
        : subscribe(
            `transaction/findByRange?range=${selectedBtn.range}`,
            id,
            (_, result) => {
              if (result) setTransList(result);
            },
            (loading) => onLoading(refresh ? loading : false)
          ),
    ]);
  };

  liveData(
    "transaction",
    (match) => liveFilters.includes(match.trans_app),
    () => executeFetch(false, false)
  );

  useEffect(() => {
    executeFetch();
    // eslint-disable-next-line
  }, [selectedBtn, id]);

  return (
    <Page title="Dashboard">
      <Grid container>
        {summaryList && summaryList.length > 0 && (
          <Grid container spacing={6} sx={{ px: "2%" }} mb={5}>
            {summaryList.map((summary, index) => {
              return (
                <Grid item xs={12} sm={6} md={3}>
                  <SummaryCard
                    title={summary.title}
                    color={summary.color}
                    key={summary.color}
                    total={summary.counter}
                    formatter={
                      index < 3
                        ? parseInt(summary.counter) >= 10000000
                          ? fCurrencyShort
                          : fCurrency
                        : fNumber
                    }
                    icon={summary.icon}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            sectionTitle={selectedBtn.title}
            data={transList.filter(
              (data) => data.trans_pay_instalment !== normalPayment
            )}
            primaryKey="_id"
            filterColumn={filterKey}
            emptyIcon="receipt_long"
            loading={loading}
            createNewElement={
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <ButtonGroup size="large" aria-label="large button group">
                  {btnGroupOptions.map((btn) => {
                    return (
                      <Button
                        key={btn.key}
                        className={
                          selectedBtn.key === btn.key
                            ? appStyle.selectedGrouped
                            : null
                        }
                        onClick={() => setSelectedBtn(btn)}
                      >
                        {btn.key}
                      </Button>
                    );
                  })}
                </ButtonGroup>
                <MoreMenu
                  selected={[normalPayment ? "normal" : "instalment"]}
                  options={paymentTypes}
                  sx={{ mt: 0.5, mr: 2, ml: 4 }}
                  icon="more_vert"
                />
              </Box>
            }
            headers={getHeader(normalPayment)}
            rowData={(data) => {
              return {
                exclude: [],
                entries: [
                  {
                    value: formatDate(data.trans_timestamp, "hh:mm A, DD MMM YY"),
                    width: 160,
                  },
                  { value: maskString(data.trans_reference_id, 8, 3) },
                  {
                    value: normalPayment
                      ? data.trans_client_id
                      : data.trans_max_purchase
                      ? "Ticket"
                      : "Intalment",
                  },
                  { value: fCurrency(data.trans_amount) },
                  {
                    value: normalPayment
                      ? id
                        ? data.trans_transaction_id
                          ? "YES"
                          : "NO"
                        : data.trans_app_name
                      : data.trans_max_purchase
                      ? data.trans_so_far
                      : fCurrency(data.trans_so_far),
                  },
                  { value: data.trans_channel_name },
                  { ...getPaymentStatus(data.trans_status), width: 80 },
                  {
                    value: !normalPayment ? null : (
                      <IconButton
                        onClick={() =>
                          navigate(`${AppRoutes.insights}?id=${data.trans_id}`, true)
                        }
                      >
                        <Icon children="query_stats" />
                      </IconButton>
                    ),
                    width: 50,
                  },
                ],
              };
            }}
            selectionOptions={[]}
            filterOptions={filterOptions.filter((option) =>
              id
                ? option.value !== "trans_app_name"
                : option.value !== (normalPayment ? "-" : "trans_app_name")
            )}
            optionItems={
              normalPayment
                ? []
                : [
                    {
                      icon: "receipt_long",
                      value: "trans",
                      label: "Transactions",
                      onClick: (value, selected) => {
                        const trans = transList.filter(
                          (item) => item.trans_id === selected[0]
                        )[0];
                        navigate(
                          `${AppRoutes.instalments}?id=${trans.trans_id}&ticket=${trans.trans_non_consumable}`,
                          true
                        );
                      },
                    },
                    {
                      icon: "content_copy",
                      value: "copy",
                      label: "Copy Link",
                      onClick: (value, selected) => {
                        copyText(
                          `${linkable}${
                            transList.filter(
                              (item) => item.trans_id === selected[0]
                            )[0].trans_id
                          }`
                        );
                        showSnack("Link copied to a clipboard ");
                      },
                    },
                  ]
            }
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default RevenueSummary;
