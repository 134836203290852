import {
  getUserRole,
  getUserStatus,
  Statuses,
  UserRoles,
  getActiveUser,
  navigate,
  subscribe,
  guardRoute,
  formatPhoneNumber,
  showSnack,
  watchLiveData
} from "../utils/utils";
import DataList from "./component/@list/list";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import { Document, Page, pdfjs } from "react-pdf";
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  DialogContentText,
  CircularProgress,
} from "@mui/material";

const getTableHead = () => {
  const loggedUser = getActiveUser();
  var heads = [
    { id: "_", label: "", alignRight: false },
    
  ];

  heads = heads.concat({ id: "user_name", label: "Full Name", alignRight: false },
    { id: "user_email", label: "Email Address", alignRight: false },
    { id: "user_phone", label: "Phone number", alignRight: false },
    { id: "user_role", label: "Role", alignRight: false });

  if (loggedUser.role === UserRoles.admin) {
    heads = heads.concat({
      id: "revenue_share",
      label: "Rev %",
      alignRight: false,
    }, {
      id: "user_ref_name",
      label: "Manager",
      alignRight: false,
    });
  }
  if (loggedUser.role === UserRoles.merchant) {
    heads.push({ id: "user_app_name", label: "Business", alignRight: false });
  }
  heads.push({ id: "user_status", label: "Status", alignRight: false });
  heads.push({ id: "__" });
  return heads;
};

const UserList = () => {
  guardRoute();
  const loggedUser = getActiveUser();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [filterKey, setFilterkey] = useState("user_timestamp");
  const [preview, setPreview] = useState(false);
  const [user, setUser] = useState(null);
  const [pdfUrl, setPdfUser] = useState(null);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const userOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) =>
        executeUpdate(0, selected.join(","), "delete"),
    },
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) =>
        navigate(
          `${AppRoutes.users}/${AppRoutes.user}?id=${selected.join("")}`,
          true
        ),
    },
    {
      icon: "timer",
      value: "suspend",
      label: "Suspend",
      onClick: (value, selected) =>
        executeUpdate(Statuses.suspended, selected.join(","), "post"),
    },
    {
      icon: "password",
      value: "password",
      label: "Reset Password",
      onClick: (value, selected) => {
        console.log(selected.join(","));
        subscribe(
          `auth/resetPasswordRequest`,
          selected.join(","),
          (error, result) => {
            showSnack(error ? error.message : result.message);
          },
          (loading) => onLoading(loading)
        );
      },
    },
    {
      icon: "check",
      value: "approve",
      label: "Approve",
      onClick: (value, selected) =>
        executeUpdate(Statuses.approved, selected.join(","), "post"),
    },
    {
      icon: "fingerprint",
      value: "identity",
      label: "National ID",
      onClick: (value, selected) => {
        const user = dataList.filter(
          (data) => data._id === selected.join("")
        )[0];
        const docUrl = `https://files.easypay.co.tz/attachments/${user.user_doc}`;
        setPdfUser(docUrl);
        setPreview(true);
        setUser(user);
      },
    },
  ];
  const filterOptions = [
    { value: "user_name", label: "Full name", onClick: setFilterkey },
    { value: "user_email", label: "Email Address", onClick: setFilterkey },
    { value: "user_phone", label: "Phone Number", onClick: setFilterkey },
    { value: "user_timestamp", label: "Created On", onClick: setFilterkey },
  ];

  const executeFetch = (refresh = false) => {
    subscribe(
      "user/find",
      null,
      (error, result) => {
        if (result) setDataList(result);
      },
      (loading) => onLoading(refresh ? loading : refresh)
    );
  };

  const handleClose = () => {
    setPreview(false);
    setUser(null);
  };
  useEffect(() => {
    executeFetch(true);
    watchLiveData("user", (data) => {
      const fetch =
        (loggedUser.role !== UserRoles.admin &&
          (data.user_ref_id === loggedUser.id || data._id === loggedUser.id)) ||
        loggedUser.role === UserRoles.admin;

      if (fetch) executeFetch(false);
    });
    // eslint-disable-next-line
  }, []);

  const executeUpdate = (status, id, action) => {
    if (action === "delete") {
      subscribe(`user/delete`, id, null, (loading) => onLoading(loading));
    } else {
      subscribe(
        "user/update",
        { _id: id, user_status: status },
        null,
        (loading) => onLoading(loading)
      );
    }
  };

  return (
    <div>
      <DataList
        sectionTitle="Users"
        createNewLabel={loggedUser.status ? "Create user" : null}
        data={dataList}
        primaryKey="_id"
        filterColumn={filterKey}
        emptyIcon="groups"
        avatarKey="user_name"
        loading={loading}
        onCreateNewItem={() =>
          navigate(`${AppRoutes.users}/${AppRoutes.user}`, true)
        }
        headers={getTableHead()}
        rowData={(data) => {
          const _entries = [
            { value: data.user_avatar, avatar: true },
            { value: data.user_name, width: 160 },
            { value: data.user_email },
            { value: formatPhoneNumber(data.user_phone) || "-" },
            { ...getUserRole(data.user_role) },
            {
              value:
                loggedUser.role !== UserRoles.admin
                  ? null
                  : data.user_role === UserRoles.merchant
                  ? data.revenue_share
                  : `-`,
              width: 80,
            },
            {
              value:
                loggedUser.role === UserRoles.merchant
                  ? data.user_app_name || "un-assigned"
                  : data.user_ref_name || "Owner",
            },
            { ...getUserStatus(data.user_status) },
          ];
          return {
            exclude: [
              loggedUser.role !== UserRoles.admin || !loggedUser.superAdmin
                ? "delete"
                : "",
              data.user_status !== Statuses.approved ? "suspend" : "approve",
              data.user_role !== UserRoles.merchant ? "identity" : "",
            ],
            entries: _entries,
          };
        }}
        selectionOptions={userOptions.filter(
          (option) =>
            (loggedUser.role === UserRoles.admin && loggedUser.superAdmin
              ? option.value === "delete"
              : "") ||
            option.value === "approve" ||
            option.value === "suspend" ||
            option.value === "password"
        )}
        filterOptions={filterOptions}
        optionItems={userOptions}
      />

      {user && (
        <Dialog
          open={preview}
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {user.user_name} National ID
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Document
                file={pdfUrl}
                loading={
                  <p align="center">
                    <CircularProgress />
                  </p>
                }
              >
                <Page scale={0.5} pageNumber={1} />
              </Document>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default UserList;
