import {
  copyText,
  subscribe,
  getActiveUser,
  getUserStatus,
  guardRoute,
  maskString,
  navigate,
  showSnack,
  Statuses,
  UserRoles,
  formatDate,
  getStartTimeAsDate,
  watchLiveData,
} from "../utils/utils";
import DataList from "./component/@list/list";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";

const TABLE_HEAD = [
  { id: "app_id", label: "Public ID", alignRight: false },
  { id: "app_name", label: "Public Name", alignRight: false },
  { id: "app_timestamp", label: "Created On", alignRight: false },
  { id: "api_key", label: "API Key", alignRight: false },
  { id: "app_operation_start", label: "Opening", alignRight: false },
  { id: "app_operation_hours", label: "Operating", alignRight: false },
  { id: "app_status", label: "Status", alignRight: false },
  { id: "_" },
];

const AppList = () => {
  guardRoute();
  const loggedUser = getActiveUser();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [filterKey, setFilterkey] = useState("app_timestamp");

  const appOperations = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) =>
        executeUpdate(0, selected.join(","), "delete"),
    },
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) =>
        navigate(
          `${AppRoutes.apps}/${AppRoutes.app}?id=${selected.join("")}`,
          true
        ),
    },
    {
      icon: "timer",
      value: "suspend",
      label: "Suspend",
      onClick: (value, selected) =>
        executeUpdate(Statuses.suspended, selected.join(","), "post"),
    },
    {
      icon: "check",
      value: "approve",
      label: "Approve",
      onClick: (value, selected) =>
        executeUpdate(Statuses.approved, selected.join(","), "post"),
    },
    {
      icon: "paid",
      value: "revenue",
      label: "Revenue",
      onClick: (value, selected) => {
        navigate(
          `${AppRoutes.apps}/${AppRoutes.appRevenue}?id=${selected.join("")}`,
          true
        );
      },
    },
    {
      icon: "content_copy",
      value: "copy",
      label: "Copy API Key",
      onClick: (value, selected) => {
        copyText(
          dataList.filter((item) => item._id === selected[0])[0].api_key
        );
        showSnack("API Key was copied to a clipboard ");
      },
    },
    {
      icon: "key",
      value: "generate",
      label: "Generate API key",
      onClick: (value, selected) => {
        subscribe(
          `apps/generatekey`,
          selected,
          (error, result) => {
            showSnack(error ? error.message : result.message);
          },
          (loading) => onLoading(loading)
        );
      },
    },
  ];

  const filterOptions = [
    { value: "app_name", label: "App name", onClick: setFilterkey },
    { value: "app_timestamp", label: "Created On", onClick: setFilterkey },
  ];

  const executeUpdate = (status, id, action) => {
    if (action === "delete") {
      subscribe(
        `apps/delete`,
        id,
        (error, result) => {
          showSnack(error ? error.message : result.message);
        },
        (loading) => onLoading(loading)
      );
    } else {
      subscribe(
        "apps/update",
        { _id: id, app_status: status },
        (error, result) => {
          showSnack(error ? error.message : result.message);
        },
        (loading) => onLoading(loading)
      );
    }
  };

  const executeFetch = (refresh) => {
    subscribe(
      "apps/find",
      null,
      (error, result) => {
        if (result) setDataList(result);
      },
      (loading) => onLoading(refresh ? loading : false)
    );
  };

  useEffect(() => {
    executeFetch(true);
    watchLiveData("app", (data) => {
      const fetch =
        (loggedUser.role !== UserRoles.admin &&
          data.app_owner === loggedUser.id) ||
        loggedUser.role === UserRoles.admin;

      if (fetch) executeFetch(false);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <DataList
      sectionTitle="Apps"
      createNewLabel={
        loggedUser.role === UserRoles.supervisor || !loggedUser.status
          ? null
          : "Create App"
      }
      data={dataList}
      primaryKey="_id"
      filterColumn={filterKey}
      emptyIcon="apps"
      avatarKey="app_name"
      loading={loading}
      onCreateNewItem={() =>
        navigate(`${AppRoutes.apps}/${AppRoutes.app}`, true)
      }
      headers={TABLE_HEAD}
      rowData={(data) => {
        return {
          exclude: [
            loggedUser.role !== UserRoles.admin ||
            data.app_status === Statuses.approved
              ? "approve"
              : "",
            loggedUser.role !== UserRoles.admin &&
            data.app_status === Statuses.suspended
              ? "edit"
              : "",
            loggedUser.role !== UserRoles.admin &&
            data.app_status === Statuses.suspended
              ? "generate"
              : "",
            loggedUser.role !== UserRoles.admin &&
            data.app_status === Statuses.suspended
              ? "copy"
              : "",
            loggedUser.role === UserRoles.admin && loggedUser.superAdmin
              ? ""
              : "delete",
            data.app_status === Statuses.pending ? "revenue" : "",
            data.app_status === Statuses.pending ? "generate" : "",
            data.app_status === Statuses.pending ? "copy" : "",
            data.app_status === Statuses.suspended ? "suspend" : "",
          ],
          entries: [
            { value: data.app_id },
            { value: data.app_name },
            { value: formatDate(data.app_timestamp, "LL") },
            {
              value: maskString(data.api_key, 16, 4),
            },
            {
              value: formatDate(
                getStartTimeAsDate(data.app_operation_start).getTime(),
                "LT"
              ),
            },
            { value: `${data.app_operation_hours} hours` },
            { ...getUserStatus(data.app_status) },
          ],
        };
      }}
      selectionOptions={appOperations.filter(
        (option) =>
          (loggedUser.role === UserRoles.admin && loggedUser.superAdmin
            ? option.value === "delete"
            : "") ||
          option.value === "generate" ||
          (option.value === "approve" && loggedUser.role === UserRoles.admin) ||
          option.value === "suspend"
      )}
      filterOptions={filterOptions}
      optionItems={appOperations}
    />
  );
};
export default AppList;
