import {
  Grid,
  FormControl,
  Select,
  Icon,
  InputAdornment,
  IconButton,
  MenuItem,
} from "@mui/material";
import {
  getParams,
  navigate,
  isError,
  validateFields,
  cleanObject,
  subscribe,
  getActiveUser,
  UserRoles,
  guardRoute,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";
import { coreStyles } from "../theme/style";

const chargeRef = {
  charge_amount: "",
  charge_range_start: "",
  charge_range_end: "",
  charge_type: true,
};

const validationRef = {
  charge_amount: {
    error: true,
    touched: false,
    message: "Invalid charge amount",
  },
  charge_range_start: {
    error: false,
    touched: true,
    message: "Invalid charge start range",
  },
  charge_range_end: {
    error: true,
    touched: false,
    message: "Invalid charge end range",
  },
};

export default function ChargeEdit() {
  const loggedUser = getActiveUser();
  const appStyle = coreStyles();
  guardRoute(false, loggedUser.role === UserRoles.admin && loggedUser.superAdmin);
  const { id } = getParams();
  const [charge, setCharge] = useState(chargeRef);
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });

  useEffect(() => {
    setTimeout(() => {
      subscribe(
        `charges/find?id=${id || "-"}`,
        null,
        (_, result) => {
          if (result && result._id) {
            onValueChange(result.charge_amount, "charge_amount");
            onValueChange(result.charge_range_end, "charge_range_end");
            onValueChange(result.charge_range_start, "charge_range_start");
            onValueChange(result.charge_type, "charge_type");
            setCharge({ ...chargeRef, ...result });
          }
        },
        (loading) => setStatus({ ...{ loading: loading } })
      );
    }, 500);
    // eslint-disable-next-line
  }, [id]);

  const createUpdateCharge = () => {
    subscribe(
      `charges/${id ? "update" : "create"}`,
      cleanObject({ ...charge, _id: id }),
      (error, result) =>
        setStatus({
          ...{
            error: error != null,
            message: result ? result.message : error.message,
          },
        }),
      (loading) => setStatus({ ...{ loading: loading } })
    );
  };

  const onValueChange = (value, tag) => {
    charge[tag] = value;
    const end = charge.charge_range_end;
    const start = charge.charge_range_start;
    var error = parseInt(start) >= parseInt(end);

    if (tag.includes("_start") && end.length > 0) {
      validation["charge_range_start"] = {
        ...validation["charge_range_start"],
        message: !error
          ? validation["charge_range_start"].message
          : "Start range should be lower than end range",
      };
    }

    if (tag.includes("_end") && start.length > 0) {
      validation["charge_range_end"] = {
        ...validation["charge_range_end"],
        message: !error
          ? validation["charge_range_end"].message
          : "End range should be larger than start range",
      };
    }

    if (tag.includes("_end")){

    }

    console.log(
      
    );
    validation[tag] = {
      ...validation[tag],
      error:error || 
      isNaN(value) || 
      ((value.length === 0 || (value.length > 0 && parseInt(value) === 0)) && !tag.includes("_type")),
      touched: true,
    };

    setCharge({ ...charge });
    setValidation({ ...validation });
    setValidated(validateFields(validation));
  };

  return (
    <EditPage
      title={id ? "Edit Charge" : "Add Charge"}
      status={status}
      onSave={() => createUpdateCharge()}
      validated={validated && !status.loading}
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setCharge({ ...chargeRef });
          navigate(`${AppRoutes.charges}`, true);
        }
      }}
    >
      <Grid item xs={12} sm={6}>
        <EasyTextEdit
          placeholder="Charge Amount"
          type="text"
          id="charge_amount"
          errorText={validation.charge_amount.message}
          error={isError(validation.charge_amount)}
          disabled={status.loading}
          value={charge.charge_amount}
          icon="paid"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <Select
            className={appStyle.inputs}
            inputlabelprops={{ shrink: true }}
            disabled={status.loading}
            value={charge.charge_type}
            onChange={(event) =>
              onValueChange(event.target.value, "charge_type")
            }
            startAdornment={
              <InputAdornment position="end">
                <IconButton edge="center">
                  <Icon children="alt_route" className={appStyle.input_icons} />
                </IconButton>
              </InputAdornment>
            }
          >
            {[true, false].map((_value) => {
              return (
                <MenuItem value={_value} className={appStyle.inputs}>
                  &nbsp;&nbsp;&nbsp;
                  {_value ? "Internal Change" : "External Charge"}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <EasyTextEdit
          placeholder="Charge start range"
          type="text"
          id="charge_range_start"
          errorText={validation.charge_range_start.message}
          error={isError(validation.charge_range_start)}
          disabled={status.loading}
          value={charge.charge_range_start}
          icon="first_page"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <EasyTextEdit
          placeholder="Charge end range"
          type="text"
          id="charge_range_end"
          errorText={validation.charge_range_end.message}
          error={isError(validation.charge_range_end)}
          disabled={status.loading}
          value={charge.charge_range_end}
          icon="last_page"
          onChange={onValueChange}
        />
      </Grid>
    </EditPage>
  );
}
