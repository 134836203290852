import { configureStore } from "@reduxjs/toolkit";
import navigatorReducer from "./navigator";
import snackbarReducer from "./snackbar";
import socketReducer from "./socket";
const AppStore = configureStore({
  reducer: {
    navigator: navigatorReducer,
    snackbar: snackbarReducer,
    socket: socketReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default AppStore;
