import {
  Typography,
  Grid,
  Link,
  Card,
  Button,
  CircularProgress,
  ButtonGroup,
  Divider,
  Icon,
} from "@mui/material";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { coreStyles } from "../theme/style";
import AppRoutes from "../routing/routes";
import { useState, useEffect } from "react";
import "react-dropzone-uploader/dist/styles.css";
import {
  isError,
  validateFields,
  fCurrency,
  validatePhone,
  getParams,
  validateUrl,
  getPaymentStatus,
  formatMills,
  subscribe,
  liveData,
  TransStatus,
  handleRefresh,
  paymentTimeout,
} from "../utils/utils";
import Status from "./component/status";
import Logo from "./component/logo";
import SupportedMno from "./component/supported-mno";
import EasyTextEdit from "./component/text-edit";
import QrCode from "./component/qrcode";

const transRef = {
  trans_client_id: "",
  trans_app_name: "",
  trans_redirect_url: "",
  trans_status: TransStatus.pending,
};

const validationRef = {
  trans_client_id: { error: true, touched: false },
};

const mobile = "mobile";

const card = "card";

const sectionOptions = [
  { key: mobile, title: "Mobile Payment" },
  { key: card, title: "Card Payment" },
];

const Payment = () => {
  const [trans, setTrans] = useState(transRef);
  const [section, setSection] = useState(sectionOptions[0]);
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const appStyle = coreStyles();
  const { id } = getParams();
  const [filters, setFilters] = useState([]);
  const [status, setStatus] = useState({ error: false, message: null });

  const handleSubmit = (event) => {
    event.preventDefault();
    trans.trans_generate_link = false;
    liveData(
      "transaction",
      (data) => data.trans_app === trans.trans_app && id === data.trans_id,
      (data) => updateTrans({ ...trans, ...data })
    );

    subscribe(
      `transaction/pay`,
      trans,
      (error, result) => {
        if (error) {
          setStatus({
            ...{
              error: error != null,
              message: result ? result.message : error.message,
            },
          });
        }
      },
      (_) => setStatus({ ...{ loading: true } })
    );
  };

  const onValueChange = (value, tag) => {
    trans[tag] = value;
    if (tag.includes("_client_id")) {
      validation.trans_client_id = {
        error: !validatePhone(value),
        touched: true,
      };
    }
    updateTrans({ ...trans });
    setValidation({ ...validation });
    setValidated(validateFields(validation));
  };

  const updateTrans = (transaction) => {
    if (transaction === null) {
      trans.trans_status = "NOT FOUND";
      trans.trans_remarks =
        "We couldn't find your transaction due the fact that provided reference ID is wrong.";
      setTrans(trans);
    }
    const _trans = transaction || trans;
    setTrans(_trans);
    if (
      _trans.trans_status === TransStatus.failed ||
      _trans.trans_status === TransStatus.notFound
    ) {
      setStatus({ ...status, loading: false });
      handleRefresh(_trans);
    }
  };

  const fetchPaymentInfo = () => {
    subscribe(
      `transaction/information?id=${id ? id : "-"}&type=pay`,
      null,
      (_, result) => {
        if (
          result &&
          result.trans_type_mobile &&
          filters.indexOf(card) === -1
        ) {
          filters.push(card);
        }
        if (
          result &&
          !result.trans_type_mobile &&
          filters.indexOf(mobile) === -1
        ) {
          filters.push(mobile);
        }

        if (result && result.trans_client_id) {
          onValueChange(result.trans_client_id, "trans_client_id");
        }
        setFilters(filters);
        updateTrans(_ ? null : result);
      },
      (loading) => setStatus({ ...{ loading: loading } })
    );
  };

  useEffect(() => {
    fetchPaymentInfo();
    // eslint-disable-next-line
  }, []);

    useEffect(() => {
      if (status.loading) {
        window.addEventListener("beforeunload", preventReload);
        return () => {
          window.removeEventListener("beforeunload", preventReload);
        };
      }
    }, [status.loading]);

    const preventReload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

  const getTimeSeconds = (time) => (paymentTimeout - time) | 0;

  const renderTime = (time) => {
    return (
      <div className="time-wrapper">
        <div className="time">{formatMills(time)}</div>
      </div>
    );
  };

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid
        item
        xs={12}
        md={trans && trans.trans_status !== TransStatus.pending ? 5 : 8}
      >
        {trans.trans_app_name.length > 2 &&
          trans.trans_status === TransStatus.pending && (
            <Card className={appStyle.loginCard}>
              <Typography
                variant="h2"
                color="primary.darker"
                align="center"
                mb={3}
                className={appStyle.title}
              >
                Settle your payment
              </Typography>

              <Typography
                variant="h5"
                align="center"
                color="text.disabled"
                mb={6}
              >
                {filters.length === 1 && filters.indexOf(card) !== -1
                  ? `Please unlock your phone and wait for PIN request to settle your transaction.`
                  : filters.length === 1 && filters.indexOf(mobile) !== -1
                  ? `Please fill out your personal and correct information before settling your payment`
                  : `For Card payments, make sure you provide correct information while for mobile payments remember to unlock your phone and wait for PIN request.`}
              </Typography>

              <Divider />

              <Grid container spacing={2} mt={4}>
                {status && status.message && (
                  <Grid item xs={12} mb={2}>
                    <Status
                      error={status.error}
                      timeout={5000}
                      message={status.message}
                      onClose={() => setStatus({ ...status, message: null })}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={7}>
                  <Typography align="left" variant="body1" mb={4}>
                    Pay total amount of
                  </Typography>

                  <Typography
                    color="text.disabled"
                    align="left"
                    variant="h1"
                    mb={4}
                  >
                    {`${fCurrency(trans.trans_amount)}`}
                  </Typography>

                  <Typography align="left" variant="body2" mb={1}>
                    Paying to
                  </Typography>

                  <Typography align="left" variant="h6" mb={4}>
                    {trans.trans_app_name}
                  </Typography>

                  <Typography align="left" variant="body2" mb={1}>
                    Payment for
                  </Typography>

                  <Typography align="left" variant="h6" mb={2}>
                    {trans.trans_reference}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={5}>
                  <ButtonGroup size="large" aria-label="large button group">
                    {sectionOptions
                      .filter((sec) => filters.indexOf(sec.key) === -1)
                      .map((sect) => {
                        return (
                          <Button
                            key={sect.key}
                            className={
                              section.key === sect.key
                                ? appStyle.selectedGrouped
                                : null
                            }
                            onClick={() => setSection(sect)}
                          >
                            {sect.title}
                          </Button>
                        );
                      })}
                  </ButtonGroup>

                  <SupportedMno />

                  {section.key === mobile && (
                    <Grid item xs={12} mt={2}>
                      <EasyTextEdit
                        placeholder="Phone number"
                        type="phone"
                        id="trans_client_id"
                        errorText="Invalid phone number"
                        error={isError(validation.trans_client_id)}
                        disabled={status.loading}
                        value={trans.trans_client_id}
                        icon="phone"
                        onChange={onValueChange}
                      />

                      {!status.loading && (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={handleSubmit}
                          disabled={!(!status.loading && validated)}
                          className={appStyle.innerButton}
                        >
                          Pay Now
                        </Button>
                      )}

                      {status.loading && validated && (
                        <Grid item xs={12} mt={4} align="center">
                          <CountdownCircleTimer
                            isPlaying={status.loading && validated}
                            duration={paymentTimeout}
                            size={150}
                            strokeWidth={9}
                            colors={[
                              "#061B64",
                              "#F7B801",
                              "#A34000",
                              "#A30000",
                            ]}
                            colorsTime={[45, 30, 2, 0]}
                            onComplete={() => {
                              fetchPaymentInfo();
                              setStatus({ ...status, loading: false });
                              return { shouldRepeat: false, delay: 1.5 }; // repeat animation in 1.5 seconds
                            }}
                          >
                            {({ elapsedTime, color }) => (
                              <span style={{ color }}>
                                {renderTime(getTimeSeconds(elapsedTime))}
                              </span>
                            )}
                          </CountdownCircleTimer>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {section.key === card && (
                    <Grid item xs={12}>
                      <Typography align="center" variant="h6" mt={8} mb={1}>
                        Comming soon...
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12} mt={4} textAlign="center">
                  <Link
                    href={
                      !validateUrl(trans.trans_redirect_url)
                        ? AppRoutes.login
                        : trans.trans_redirect_url
                    }
                    variant="body2"
                    className={appStyle.links}
                  >
                    &nbsp;&nbsp;
                    {!validateUrl(trans.trans_redirect_url) ||
                    trans.trans_redirect_url.includes("easypay.co.tz")
                      ? "Cancel and Go Back"
                      : `Go back to ${trans.trans_app_name}`}
                  </Link>
                </Grid>
              </Grid>
            </Card>
          )}

        {trans.trans_app_name.length === 0 &&
          trans.trans_status === TransStatus.pending && (
            <Card className={appStyle.loginCard}>
              <p align="center">
                <CircularProgress sx={{ mt: 18 }} />
                <Typography
                  variant="h6"
                  color="primary.darker"
                  align="center"
                  sx={{ mb: 18, mt: 2 }}
                  className={appStyle.title}
                >
                  Fetching transaction information...
                </Typography>
              </p>
            </Card>
          )}

        {trans.trans_status !== TransStatus.pending && (
          <Card className={appStyle.loginCard}>
            <Grid container justifyContent="center">
              <Grid
                item
                mt={7}
                mb={trans.trans_status === TransStatus.completed ? 3 : 7}
              >
                <Icon
                  children={getPaymentStatus(trans.trans_status).icon}
                  color={getPaymentStatus(trans.trans_status, false).color}
                  className={appStyle.icon_standalone}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.primary" align="center" variant="h2">
                  {trans.trans_status}
                  <br />
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                mt={trans.trans_status === TransStatus.completed ? 4 : 8}
                mb={trans.trans_status === TransStatus.completed ? 2 : 8}
              >
                <Typography color="text.disabled" align="center" variant="h5">
                  {trans.trans_status === TransStatus.completed ||
                  trans.trans_status === TransStatus.notFound
                    ? `${trans.trans_remarks}${
                        trans.trans_status === TransStatus.completed
                          ? ".\nPlease snap the QR code below and store it for your record."
                          : ""
                      }`
                    : `Your transaction failed due to ${trans.trans_remarks.replace(
                        "Muamala wako Haukukamilika",
                        "some technical issues"
                      )}`}
                  <br />
                </Typography>
                {trans.trans_status === TransStatus.completed && (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <QrCode data={trans.trans_receipt} />

                    <Grid item xs={12} mt={4} textAlign="center">
                      <Link
                        href={
                          !validateUrl(trans.trans_redirect_url)
                            ? AppRoutes.login
                            : trans.trans_redirect_url
                        }
                        variant="body2"
                        className={appStyle.links}
                      >
                        &nbsp;&nbsp;
                        {!validateUrl(trans.trans_redirect_url) ||
                        trans.trans_redirect_url.includes("easypay.co.tz")
                          ? "Cancel and Go Back"
                          : `Go back to ${trans.trans_app_name}`}
                      </Link>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default Payment;
