import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import QRCode from "react-google-qrcode";

QrCode.propTypes = {
  data: PropTypes.string,
  size: PropTypes.number,
};

export default function QrCode({ size = 170, data = "" }) {
  const qrCode = (
    <Grid item xs={6} textAlign="center">
      <Box sx={{ mt: 2, mb: 2 }}>
        <QRCode data={data} size={size} framed />
      </Box>
    </Grid>
  );
  return <>{qrCode}</>;
}
