import {
  Grid,
  FormControl,
  Typography,
  FormControlLabel,
  InputAdornment,
  Icon,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
} from "@mui/material";
import { coreStyles } from "../theme/style";
import {
  navigate,
  guardRoute,
  validatePhone,
  getActiveUser,
  fCurrency,
  validateName,
  subscribe,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";

const transRef = {
  trans_amount: 0,
  trans_type: 2,
  trans_account: "",
  trans_account_org: "CRDB",
  trans_currency: "TZS",
  trans_account_name: "",
  trans_min_threshold: 0,
  auto_disburse: false,
  trans_frequency: "month",
  password: "",
  remember: true,
};

var timeoutId = -1;

const DisburseEdit = () => {
  guardRoute();
  const loggedUser = getActiveUser();
  transRef.auto_disburse = loggedUser.auto;
  const appStyle = coreStyles();
  const [transfer, setTransfer] = useState(transRef);
  const [options, setOptions] = useState([]);
  const [transferState, setStransferState] = useState({});
  const [requestAmount, setRequest] = useState(false);
  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });
  useEffect(() => {
    setTimeout(() => {
      Promise.all([
        subscribe(
          `transfer/options`,
          null,
          (_, result) => {
            if (result) {
              setOptions(result);
            }
          },
          (loading) => setStatus({ ...{ loading: loading } })
        ),
        subscribe(
          `transfer/state`,
          null,
          (_, result) => {
            if (result) {
              setStransferState(result);
              onValueChange(result.balance * 1, "trans_amount");
              onValueChange(result.minDisburse * 1, "trans_min_threshold");
            }
          },
          (loading) => setStatus({ ...{ loading: loading } })
        ),
        subscribe(
          `transfer/account`,
          null,
          (error, account) => {
            if (account && !error && account.trans_account_name) {
              transfer.trans_account_name = account.trans_account_name;
              transfer.trans_account = account.trans_account;
              transfer.trans_account_org = account.trans_account_org;
              transfer.trans_type = account.trans_type;
              transfer.trans_min_threshold = account.trans_min_threshold;
              transfer.trans_frequency = account.trans_frequency;
              setTransfer({ ...transfer });
            }
          },
          (loading) => setStatus({ ...{ loading: loading } })
        ),
      ]);
    }, 500);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const amount = transfer.trans_amount * 1;
      if (amount > 0 && amount > transferState.balance) {
        transfer.trans_amount = transferState.balance;
        setTransfer({ ...transfer });
      } else {
        setRequest(amount >= 1000);
      }
    }, 1000);
    // eslint-disable-next-line
  }, [transfer, transferState.balance, requestAmount]);

  const onValueChange = (value, tag) => {
    transfer[tag] = value;
    setTransfer({ ...transfer });
  };

  const requestPayment = () => {
    transfer.trans_merchant =
      transfer.trans_merchant || loggedUser.ref || loggedUser.id;
    subscribe(
      `transfer/create`,
      transfer,
      (error, result) =>
        setStatus({
          ...{
            error: error != null,
            message: result ? result.message : error.message,
          },
        }),
      (loading) => setStatus({ ...{ loading: loading } })
    );
  };

  const validated =
    ((!loggedUser.auto && requestAmount) ||
      (loggedUser.auto &&
        parseFloat(`${transfer.trans_min_threshold}`) >=
          transferState.minTransaction)) &&
    (transfer.trans_type === 2 
      ? transfer.trans_account.length > 10
      : validatePhone(transfer.trans_account)) &&
    transfer.trans_account_org.length > 0 &&
    validateName(transfer.trans_account_name) &&
    transfer.password.length >= 6 &&
    !status.loading;

  return (
    <EditPage
      title={loggedUser.auto ? "Disbursement Preferences" : "Request transfer"}
      status={status}
      withTitle={true}
      onSave={() => requestPayment()}
      validated={validated}
      btnLabel={loggedUser.auto ? "Save Now" : "Request Now"}
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setTransfer({ ...transRef });
          navigate(`${AppRoutes.disbursement}`, true);
        }
      }}
    >
      {!loggedUser.auto && (
        <Grid item xs={12} justifyContent="end">
          <p align="right">
            <Typography
              variant="h5"
              color="text.disabled"
              className={appStyle.balance}
            >
              <span>Available balance</span>{" "}
              <strong>{fCurrency(transferState.balance)}</strong>
            </Typography>
          </p>
        </Grid>
      )}
      <Grid item xs={12} md={loggedUser.auto ? 4 : 6}>
        <FormControl fullWidth>
          <Select
            className={appStyle.inputs}
            inputlabelprops={{ shrink: true }}
            disabled={status.loading}
            value={transfer.trans_currency}
            onChange={(event) =>
              onValueChange(event.target.value * 1, "trans_currency")
            }
            startAdornment={
              <InputAdornment position="end">
                <IconButton edge="center">
                  <Icon
                    children="currency_exchange"
                    className={appStyle.input_icons}
                  />
                </IconButton>
              </InputAdornment>
            }
          >
            {[{ value: "TZS", label: "Tanzanian Shillings" }].map((curr) => {
              return (
                <MenuItem value={curr.value} className={appStyle.inputs}>
                  &nbsp;&nbsp;&nbsp;{curr.label}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      {loggedUser.auto && (
        <Grid item xs={12} md={loggedUser.auto ? 5 : 6}>
          <EasyTextEdit
            placeholder="Minimum amount to disburse"
            type="text"
            id="trans_min_threshold"
            errorText={`Amount should be greater or equal to ${fCurrency(
              transferState.minTransaction
            )}`}
            disabled={status.loading}
            error={
              parseFloat(`${transfer.trans_min_threshold}`) <
              transferState.minTransaction
            }
            value={transfer.trans_min_threshold}
            icon="paid"
            onChange={onValueChange}
          />
        </Grid>
      )}

      {!loggedUser.auto && (
        <Grid item xs={12} md={6}>
          <EasyTextEdit
            placeholder="Amount to disburse"
            type="text"
            id="trans_amount"
            errorText=""
            disabled={status.loading}
            value={transfer.trans_amount}
            icon="paid"
            onChange={onValueChange}
          />
        </Grid>
      )}

      {loggedUser.auto && (
        <Grid item xs={12} md={loggedUser.auto ? 3 : 6}>
          <FormControl fullWidth>
            <Select
              className={appStyle.inputs}
              inputlabelprops={{ shrink: true }}
              value={transfer.trans_frequency}
              onChange={(event) => {
                onValueChange(event.target.value, "trans_frequency");
              }}
              startAdornment={
                <InputAdornment position="end">
                  <IconButton edge="center">
                    <Icon children="today" className={appStyle.input_icons} />
                  </IconButton>
                </InputAdornment>
              }
            >
              {[
                { value: "month", label: "Monthly" },
                { value: "isoWeek", label: "Weekly" },
                { value: "day", label: "Daily" },
              ].map((freq) => {
                return (
                  <MenuItem value={freq.value} className={appStyle.inputs}>
                    &nbsp;&nbsp;&nbsp;{freq.label}
                    <br />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <Select
            className={appStyle.inputs}
            inputlabelprops={{ shrink: true }}
            value={transfer.trans_type}
            onChange={(event) => {
              onValueChange(event.target.value, "trans_type");
              onValueChange(
                event.target.value === 2 ? "CRDB" : "Tigo Pesa",
                "trans_account_org"
              );
            }}
            startAdornment={
              <InputAdornment position="end">
                <IconButton edge="center">
                  <Icon
                    children="admin_panel_settings"
                    className={appStyle.input_icons}
                  />
                </IconButton>
              </InputAdornment>
            }
          >
            {[
              { value: 2, label: "Bank" },
              { value: 3, label: "Mobile Wallet" },
            ].map((curr) => {
              return (
                <MenuItem value={curr.value} className={appStyle.inputs}>
                  &nbsp;&nbsp;&nbsp;{curr.label}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <Select
            className={appStyle.inputs}
            inputlabelprops={{ shrink: true }}
            value={transfer.trans_account_org}
            onChange={(event) =>
              onValueChange(event.target.value, "trans_account_org")
            }
            startAdornment={
              <InputAdornment position="end">
                <IconButton edge="center">
                  <Icon
                    children={
                      transfer.trans_type === 2
                        ? "account_balance"
                        : "account_balance_wallet"
                    }
                    className={appStyle.input_icons}
                  />
                </IconButton>
              </InputAdornment>
            }
          >
            {options
              .filter((option) => option.type === transfer.trans_type)
              .map((option) => {
                return (
                  <MenuItem value={option.name} className={appStyle.inputs}>
                    &nbsp;&nbsp;&nbsp;{option.name}
                    <br />
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <EasyTextEdit
          placeholder={transfer.trans_type === 2 ? "Account Number" : "Phone Number"}
          type="text"
          id="trans_account"
          errorText={
            transfer.trans_type === 2
              ? `Wrong bank account number`
              : `Invalid phone number`
          }
          error={
            transfer.trans_account.length === 0
              ? false
              : !(transfer.trans_type === 2
                  ? transfer.trans_account.length > 10
                  : validatePhone(transfer.trans_account))
          }
          disabled={status.loading}
          value={transfer.trans_account}
          icon={transfer.trans_type === 2 ? "account_balance_wallet" : "call"}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <EasyTextEdit
          placeholder="Account holder name"
          type="text"
          id="trans_account_name"
          errorText=""
          disabled={status.loading}
          value={transfer.trans_account_name}
          icon="account_circle"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <EasyTextEdit
          placeholder="Password"
          type="password"
          id="password"
          errorText=""
          disabled={status.loading}
          value={transfer.password}
          icon="password"
          password={true}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} md={6} mt={1}>
        <FormControlLabel
          checked={transfer.remember}
          disabled={true}
          onChange={(event) => onValueChange(event.target.checked, "remember")}
          control={<Checkbox />}
          label={
            <Typography className={appStyle.inputs} variant="body2">
              Remember account details for all disbursements
            </Typography>
          }
        />
      </Grid>
    </EditPage>
  );
};

export default DisburseEdit;
