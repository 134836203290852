import {
  Button,
  Card,
  Grid,
  Link,
  CircularProgress,
  Typography,
} from "@mui/material";
import { coreStyles } from "../theme/style";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import {
  guardRoute,
  getParams,
  navigate,
  isError,
  validateFields,
  validatePassword,
  subscribe,
} from "../utils/utils";
import Logo from "./component/logo";
import Status from "./component/status";
import EasyTextEdit from "./component/text-edit";

const label = "Reset Password";

const authRef = {
  password: "",
  code: "",
  password_conf: "",
};

const validationRef = {
  password: { error: true, touched: false },
  password_conf: { error: true, touched: false },
};

const AuthResetPassword = () => {
  guardRoute(true);
  const appStyle = coreStyles();
  const [onProcessing, setonProcessing] = useState(false);
  const [btnLabel, setLabel] = useState(label);
  const { code } = getParams();
  const [level, setLevel] = useState("");
  const [authProps, setAuthProps] = useState(authRef);
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState({ error: false, message: null });

  const handleSubmit = (event) => {
    event.preventDefault();
    subscribe(
      "auth/resetPassword",{...authProps, code: code},
      (error, result) => {
        setLabel(label);
        setStatus({
          ...{
            error: error != null,
            message: result ? result.message : error.message,
          },
        });
      },
      (loading) => {
        setLabel("");
        setonProcessing(loading);
      }
    );
  };

  const onValueChanged = (value, isConfirm) => {
    if (isConfirm) {
      authProps["password_conf"] = value;
       validation.password_conf = { error: authProps.password !== authProps.password_conf, touched: true };
    } else {
      const strn = validatePassword(value);
      authProps["password"] = value;
      setLevel(strn);
      validation.password = { error: strn.level < 75, touched: true };
    }
    setAuthProps({ ...authProps });
    setValidation({ ...validation });
    setValidated(validateFields(validation) );
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, [btnLabel, onProcessing]);

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid item xs={12} md={5}>
        <Card className={appStyle.loginCard}>
          <Typography
            variant="h2"
            color="primary.darker"
            align="center"
            mb={4}
            className={appStyle.title}
          >
            Reset Password
          </Typography>
          <Typography
            align="center"
            variant="body1"
            mb={4}
            className={appStyle.sub_title}
          >
            Please enter your new password
          </Typography>

          <Grid container spacing={4}>
            {status && status.message && (
              <Grid item xs={12} mb={4}>
                <Status
                  error={status.error}
                  timeout={5000}
                  message={status.message}
                  onClose={() => {
                    if (status.error) {
                      setStatus({ ...{ message: null } });
                    } else {
                      navigate(AppRoutes.login, true);
                    }
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <EasyTextEdit
                placeholder="New Password"
                type="password"
                id="password"
                errorText={`Password is ${level.label}`}
                error={isError(validation.password)}
                disabled={onProcessing}
                value={authProps.password}
                icon="password"
                password={true}
                onChange={(value, _) => onValueChanged(value, false)}
              />
            </Grid>

            <Grid item xs={12}>
              <EasyTextEdit
                placeholder="Confirm New Password"
                type="password"
                id="password_conf"
                errorText={"Password doesn't match"}
                error={isError(validation.password_conf)}
                disabled={onProcessing}
                icon="password"
                value={authProps.password_conf}
                password={true}
                onChange={(value, _) => onValueChanged(value, true)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                disabled={!(validated && !onProcessing)}
                className={appStyle.loginButton}
              >
                {onProcessing && <CircularProgress color="primary" />}
                {btnLabel}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <p align="center">
                <Link
                  href={AppRoutes.login}
                  variant="body2"
                  mt={6}
                  className={appStyle.links}
                >
                  &nbsp;&nbsp;Go back to Login
                </Link>
              </p>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AuthResetPassword;
