const AppRoutes = {
  auth: "/auth",
  login: "login",
  twoFactorAuth: "2factorAuthentication",
  twoFactorConfig: "2factorConfiguration",
  register: "register",
  forgot: "forgotPassword",
  recover: "resetPassword",
  dashboard: "/dashboard",
  transaction: "/transaction",
  account: "account",
  summary: "summary",
  apps: "apps",
  app: "app",
  appRevenue: "revenue",
  users: "users",
  user: "user",
  disbursement: "disbursement",
  mail: "mail",
  reports: "reports",
  requestPay: "request",
  checkout: "checkout",
  payout: "payout",
  ticket: "ticket",
  instalments: "transactions",
  channels: "channels",
  channel: "channel",
  insights: "insights",
  charges: "charges",
  charge: "charge"
};

export default AppRoutes;