import {
  subscribe,
  getActiveUser,
  guardRoute,
  navigate,
  showSnack,
  UserRoles,
  formatDate,
  fCurrency,
} from "../utils/utils";
import DataList from "./component/@list/list";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";

const TABLE_HEAD = [
  { id: "charge_last_changed", label: "Last Updated", alignRight: false },
  { id: "charge_type", label: "Category", alignRight: false },
  { id: "charge_amount", label: "Costs", alignRight: false },
  { id: "charge_range_start", label: "Starts at", alignRight: false },
  { id: "charge_range_end", label: "Ends at", alignRight: false },
  { id: "_" },
];

const ChargeList = () => {
  const loggedUser = getActiveUser();

  guardRoute(false, loggedUser.role === UserRoles.admin && loggedUser.superAdmin);
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);

  const operationOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) => {
        subscribe(
          `charges/delete`,
          selected.join(","),
          (error, result) => {
            executeFetch();
            showSnack(error ? error.message : result.message);
          },
          (loading) => onLoading(loading)
        );
      },
    },
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) =>
        navigate(
          `${AppRoutes.charges}/${AppRoutes.charge}?id=${selected.join("")}`,
          true
        ),
    },
  ];

  const executeFetch = () => {
    subscribe(
      "charges/find",
      null,
      (_, result) => {
        if (result) setDataList(result);
      },
      (loading) => onLoading(loading)
    );
  };

  useEffect(() => {
     executeFetch();
    // eslint-disable-next-line
  }, []);

  return (
    <DataList
      sectionTitle="Charges"
      createNewLabel={
        loggedUser.role !== UserRoles.admin ? null : "New Charges"
      }
      data={dataList}
      primaryKey="_id"
      filterColumn={"charge_amount"}
      emptyIcon="paid"
      orderIn="asc"
      loading={loading}
      onCreateNewItem={() =>
        navigate(`${AppRoutes.charges}/${AppRoutes.charge}`, true)
      }
      headers={TABLE_HEAD}
      rowData={(data) => {
        return {
          exclude: [],
          entries: [
            { value: formatDate(data.charge_last_changed) },
            { value: data.charge_type ? 'Internal Charges':'External Charges' },
            { value: fCurrency(data.charge_amount) },
            { value: fCurrency(data.charge_range_start) },
            { value: fCurrency(data.charge_range_end) },
          ],
        };
      }}
      selectionOptions={[]}
      filterOptions={[]}
      optionItems={operationOptions}
    />
  );
};
export default ChargeList;
