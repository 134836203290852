import {
  Grid,
  Typography,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContentText,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import * as React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  guardRoute,
  subscribe,
  UserRoles,
  getActiveUser,
  getStartDate,
  showSnack,
  validateEmail,
  watchLiveData,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import { coreStyles } from "../theme/style";
import EasyTextEdit from "./component/text-edit";

const Reports = () => {
  const appStyle = coreStyles();
  const loggedUser = getActiveUser();
  guardRoute();
  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });
  const [pieChart, setPieChart] = useState([]);
  const [dialogVisible, showDialog] = useState(false);
  const [validated, setValidated] = useState(true);
  const [barChart, setBarChart] = useState([]);
  const [reportProps, setReportProps] = React.useState({
    start: getStartDate(-90).getTime(),
    end: getStartDate(0).getTime(),
    email: null,
  });
  const [pieConfig, setPieConfig] = useState({
    pieHole: 0.4,
    is3D: false,
    colors: [],
    chartArea: { width: "60%", height: "70%", left: "2%" },
    legend: { position: "bottom" },
    pieSliceTextStyle: { fontSize: 20 },
  });

  const exportReport = () => {
    showDialog(false);
    subscribe(
      `transaction/export?start=${reportProps.start}&end=${reportProps.end}&email=${reportProps.email}`,
      null,
      (error, result) => {
        showSnack((error || result).message);
      },
      (loading) => setStatus({ ...{ loading: loading } })
    );
  };

  const [barConfig] = useState({
    chartArea: { width: "100%", height: "70%", left: "2%" },
    legend: { position: "bottom" },
    animation: { duration: 500, startup: true },
  });

  const fetchReport = (refresh) => subscribe(
          `transaction/report`,
          null,
          (error, result) => {
            if (result != null) {
              const pData = [["Mobile Operators", "Usage Frequency"]];
              const cData = [
                ["Transaction Status", "occurrence", { role: "style" }],
              ];
              const colors = [];
              result
                .filter((data) => data.type === 2)
                .forEach((data) => {
                  pData.push([data.label, data.value * 1]);
                  colors.push(data.color);
                });
              result
                .filter((data) => data.type === 1)
                .forEach((data) => {
                  cData.push([data.label, data.value * 1, data.color]);
                });
              setPieConfig({ ...pieConfig, colors: colors });
              setPieChart(pData);
              setBarChart(cData);
            }
          },
          (loading) => setStatus({ ...{ loading: refresh ? loading: false } })
        );

  useEffect(() => {
    fetchReport(true);
    watchLiveData("transaction", (data) => {
      if (loggedUser.role === UserRoles.admin) fetchReport(false);
    });
    // eslint-disable-next-line
  }, []);

  const handleEmail = (value) => {
    setValidated(value.length > 0 && !validateEmail(value) ? false : true);
    setReportProps({...reportProps, email: value});
  }

  return (
    <EditPage
      title={"Reports"}
      status={status}
      onSave={() => showDialog(true)}
      validated={true}
      padding={0}
      showBtn={loggedUser.role <= UserRoles.merchant}
      btnIcon="import_export"
      btnLabel={"Export Data (.XLS)"}
      onFinish={() => {}}
    >
      {pieChart && pieChart.length > 0 && (
        <Grid item xs={6}>
          <Typography color="text.disabled" variant="h5">
            Channel Usage
          </Typography>
          <Chart
            chartType="PieChart"
            width="100%"
            height="500px"
            data={pieChart}
            options={pieConfig}
          />
        </Grid>
      )}

      {barChart && barChart.length > 0 && (
        <Grid item xs={6}>
          <Typography color="text.disabled" variant="h5">
            Transaction behaviour
          </Typography>
          <Chart
            chartType="ColumnChart"
            width="100%"
            height="500px"
            data={barChart}
            options={barConfig}
          />
        </Grid>
      )}

      {dialogVisible && (
        <Dialog
          open={dialogVisible}
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ px: 4, mt: 2 }} id="alert-dialog-title">
            Report data range
          </DialogTitle>
          <DialogContent>
            <DialogContentText xs={{ mt: 5 }} id="alert-dialog-description">
              <Grid container spacing={4} sx={{ py: 3, px: 3 }}>
                {loggedUser.role === UserRoles.admin && (
                  <Grid item xs={12} sm={12}>
                    <EasyTextEdit
                      placeholder="Merchant account email address (Optional)"
                      type="text"
                      id="emails"
                      errorText="Invalid email address"
                      error={!validated}
                      disabled={status.loading}
                      value={reportProps.email}
                      icon="alternate_email"
                      onChange={(value, key) => handleEmail(value)}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={5}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      value={reportProps.start}
                      inputlabelprops={{ shrink: true }}
                      minDate={1640995201000}
                      maxDate={getStartDate(0).getTime()}
                      onChange={(value) =>
                        setReportProps({
                          ...reportProps,
                          start: value.getTime(),
                        })
                      }
                      renderInput={(params) => (
                        <FormControl variant="filled" fullWidth>
                          <OutlinedInput
                            {...params}
                            placeholder="Start Date"
                            type="text"
                            disabled={status.loading}
                            name="start"
                            className={appStyle.inputs}
                          />
                        </FormControl>
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item sm={2}>
                  <Typography
                    sx={{ mt: 2 }}
                    variant="h6"
                    color="text.dissabled"
                    align="center"
                    edge="end"
                  >
                    To
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      value={reportProps.end}
                      inputlabelprops={{ shrink: true }}
                      minDate={reportProps.start}
                      maxDate={getStartDate(0).getTime()}
                      onChange={(value) => {
                        setReportProps({
                          ...reportProps,
                          end: value.getTime(),
                        });
                      }}
                      renderInput={(params) => (
                        <FormControl variant="filled" fullWidth>
                          <OutlinedInput
                            {...params}
                            placeholder="Start Date"
                            type="text"
                            disabled={status.loading}
                            name="start"
                            className={appStyle.inputs}
                          />
                        </FormControl>
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ mb: 3, mr: 5 }}>
            <Button onClick={() => showDialog(false)}>DISMISS</Button>
            <Button
              onClick={() => {
                setReportProps({ ...reportProps, email: null });
                exportReport();
              }}
              disabled={!validated}
            >
              EXPORT NOW
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </EditPage>
  );
};

export default Reports;
