import {
  Grid,
  FormControl,
  Typography,
  FormControlLabel,
  InputAdornment,
  Icon,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
  Autocomplete,
  TextField,
  LinearProgress,
} from "@mui/material";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { coreStyles } from "../theme/style";
import {
  subscribe,
  getParams,
  getActiveUser,
  navigate,
  UserRoles,
  validateEmail,
  guardRoute,
  validatePhone,
  validateName,
  validateFields,
  isError,
  uploadFile,
  acceptedMimes,
  maxFileSize,
  formatPhoneNumber,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";

var roles = [
  { role: UserRoles.admin, label: "System Admin" },
  { role: UserRoles.merchant, label: "Merchant" },
  { role: UserRoles.supervisor, label: "Supervisor" },
  { role: UserRoles.sales, label: "Sale's Person" },
];

const userRef = {
  user_name: "",
  user_email: "",
  user_phone: "",
  user_role: UserRoles.sales,
  user_2fa_enabled: false,
  user_auto_disburse: false,
  user_ref_id: "",
  user_id: null,
  user_app: "",
  revenue_share: 3.5,
  user_national_id: "",
};

const validationRef = {
  user_name: { error: true, touched: false },
  user_email: { error: true, touched: false },
  user_phone: { error: true, touched: false },
};

const UserEdit = () => {
  guardRoute();
  const loggedUser = getActiveUser();
  const appStyle = coreStyles();
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(0);
  const [apps, setApps] = useState([]);
  const { id } = getParams();
  if (loggedUser.role !== UserRoles.admin) {
    roles = roles.filter(
      (role) =>
        role.role >=
        (loggedUser.role === UserRoles.supervisor
          ? UserRoles.sales
          : UserRoles.supervisor)
    );
    userRef.user_role =
      loggedUser.role === UserRoles.supervisor
        ? UserRoles.sales
        : UserRoles.supervisor;
  } else {
    userRef.user_role = UserRoles.merchant;
  }

  var [user, setUser] = useState(userRef);
  var fileToUpload = null;
  var [merchants, setMerchants] = useState([]);
  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });

  useEffect(() => {
    Promise.all([
      subscribe(`user/find?role=${UserRoles.merchant}`),
      subscribe(`user/find?id=${id || "-"}`, null, null, (loading) =>
        setStatus({ ...{ loading: loading } })
      ),
      subscribe(
        `apps/find?owner=${
          loggedUser.role === UserRoles.merchant
            ? loggedUser.id
            : user.user_ref_id || "-"
        }`
      ),
    ]).then((response) => {
      const merchants = response[0];
      const merchant = merchants[0] || {};
      const remoteUser = response[1][0] || {};
      const app = response[2][0] || {};
      if (remoteUser && remoteUser.user_name) {
        onValueChange(remoteUser.user_name, "user_name");
        onValueChange(remoteUser.user_email, "user_email");
        onValueChange(remoteUser.user_phone, "user_phone");
        onValueChange(remoteUser.user_role, "user_role");
        onValueChange(remoteUser.user_id, "user_id");
        onValueChange(remoteUser.user_ref_id, "user_ref_id");
        onValueChange(remoteUser.user_doc, "user_national_id");
        onValueChange(remoteUser.user_auto_disburse, "user_auto_disburse");
        onValueChange(
          remoteUser.revenue_share || userRef.revenue_share,
          "revenue_share"
        );
      } else {
        setUser(userRef);
      }
      onValueChange(
        remoteUser.user_app
          ? remoteUser.user_app
          : app._id
          ? app._id
          : user.user_app,
        "user_app"
      );
      onValueChange(
        remoteUser.user_ref_id
          ? remoteUser.user_ref_id
          : merchant._id
          ? merchant._id
          : user.user_national_id,
        "user_ref_id"
      );
      setMerchants(merchants);
      setApps(response[2]);
    });
    return () => setUser(userRef);
    // eslint-disable-next-line
  }, [id]);

  const createUpdateUser = () => {
    if (user.user_role <= UserRoles.merchant) {
      user.user_ref_id = null;
    }

    if (
      loggedUser.role === UserRoles.merchant ||
      loggedUser.role === UserRoles.supervisor
    ) {
      user.user_ref_id = loggedUser.id;
    }

    if (loggedUser.role === UserRoles.supervisor) {
      user.user_app = loggedUser.business;
    }
    if (user.user_role < UserRoles.supervisor) {
      user.user_app = null;
    }

    if (user.user_role !== UserRoles.merchant) {
      user.revenue_share = 0;
    }

    if (validatePhone(user.user_phone)) {
      user.user_phone = formatPhoneNumber(user.user_phone);
    }

    uploadFile(
      fileToUpload,
      (response) => {
        if (response) user.user_national_id = response;
        fileToUpload = null;
        subscribe(
          `user/${id ? "update" : "create"}`,
          { ...user, _id: id },
          (error, result) =>
            setStatus({
              ...{
                error: error != null,
                message: result ? result.message : error.message,
              },
            }),
          (loading) => setStatus({ ...{ loading: loading } })
        );
      },
      (progress) => setProgress(progress)
    );
  };

  const onValueChange = (value, tag) => {
    user[tag] = value;
    if (tag.includes("_name")) {
      validation.user_name = { error: !validateName(value), touched: true };
    }
    if (tag.includes("_email")) {
      validation.user_email = { error: !validateEmail(value), touched: true };
    }
    if (tag.includes("_phone")) {
      validation.user_phone = { error: !validatePhone(value), touched: true };
    }

    if (
      tag.includes("_ref_id") &&
      loggedUser.role === UserRoles.admin &&
      value
    ) {
      const app = apps.filter((app) =>
        loggedUser.role === UserRoles.merchant
          ? app.app_name != null
          : app.app_owner === user.user_ref_id
      )[0];
      if (!app) return;
      user.user_app = app._id;
    }
    setUser({ ...user });
    setValidation({ ...validation });
    update();
  };

  const update = () => {
    var valid = validateFields(validation);
    valid =
      user.user_role !== UserRoles.merchant
        ? valid
        : (valid && user.user_national_id.length > 1) || fileToUpload !== null;
    setValidated(valid);
  };

  const handleChangeStatus = (file, status) => {
    if (status === "done" || status === "removed") {
      fileToUpload = status === "done" ? file.file : null;
      update();
    }
  };

  return (
    <EditPage
      title={id ? "Edit User" : "Add User"}
      status={status}
      onSave={() => createUpdateUser()}
      validated={validated && !status.loading}
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setUser({ ...userRef });
          navigate(`${AppRoutes.users}`, true);
        }
      }}
    >
      <Grid item xs={12} md={user.user_role === UserRoles.merchant ? 6 : 12}>
        <EasyTextEdit
          placeholder="Full name"
          type="text"
          id="user_name"
          errorText="Invalid Person name"
          error={isError(validation.user_name)}
          disabled={status.loading}
          value={user.user_name}
          icon="person"
          onChange={onValueChange}
        />
      </Grid>

      {user.user_role === UserRoles.merchant && (
        <Grid item xs={12} md={6}>
          <EasyTextEdit
            placeholder={
              loggedUser.role === UserRoles.merchant
                ? "Merchant ID"
                : "Person ID"
            }
            type="text"
            id="user_id"
            error={false}
            disabled={true}
            value={
              user.user_id ||
              `${
                loggedUser.role === UserRoles.merchant
                  ? "Merchant ID"
                  : "Person ID"
              } not assigned`
            }
            icon="verified"
            onChange={(value, _) => {}}
          />
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <EasyTextEdit
          placeholder="Email address"
          type="email"
          id="user_email"
          errorText="Invalid email address"
          error={isError(validation.user_email)}
          disabled={id ? true : status.loading}
          value={user.user_email}
          icon="alternate_email"
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <EasyTextEdit
          placeholder="Phone number"
          type="phone"
          id="user_phone"
          errorText="Invalid phone number"
          error={isError(validation.user_phone)}
          disabled={status.loading}
          value={user.user_phone}
          icon="phone"
          onChange={onValueChange}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={
          (loggedUser.role === UserRoles.admin &&
            merchants.length > 0 &&
            user.user_role > UserRoles.merchant) ||
          (loggedUser.role === UserRoles.admin &&
            user.user_role === UserRoles.merchant) ||
          (loggedUser.role === UserRoles.merchant &&
            user.user_role >= UserRoles.supervisor &&
            apps.length > 0)
            ? 6
            : 12
        }
      >
        <FormControl fullWidth>
          <Select
            className={appStyle.inputs}
            inputlabelprops={{ shrink: true }}
            disabled={status.loading}
            value={user.user_role}
            onChange={(event) => {
              const _user_id = (
                merchants.filter((merc) => merc._id === user.user_ref_id)[0] ||
                merchants[0]
              )._id;
              onValueChange(_user_id, "user_ref_id");
              onValueChange(event.target.value * 1, "user_role");
            }}
            startAdornment={
              <InputAdornment position="end">
                <IconButton edge="center">
                  <Icon
                    children="admin_panel_settings"
                    className={appStyle.input_icons}
                  />
                </IconButton>
              </InputAdornment>
            }
          >
            {roles.map((role, index) => {
              return (
                <MenuItem
                  key={index}
                  value={role.role * 1}
                  className={appStyle.inputs}
                >
                  &nbsp;&nbsp;&nbsp;{role.label}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      {loggedUser.role === UserRoles.admin &&
        user.user_role === UserRoles.merchant && (
          <Grid item xs={12} md={6}>
            <EasyTextEdit
              placeholder="Revenue Shares"
              type="text"
              id="revenue_share"
              disabled={status.loading}
              value={user.revenue_share}
              icon="percent"
              onChange={onValueChange}
            />
          </Grid>
        )}

      {merchants.length > 0 &&
        user.user_role > UserRoles.merchant &&
        loggedUser.role === UserRoles.admin && (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Autocomplete
                filterOptions={(x) => x}
                value={
                  (
                    merchants.filter(
                      (merc) => merc._id === user.user_ref_id
                    )[0] || merchants[0]
                  ).user_name
                }
                options={merchants.map((ref) => {
                  return { label: ref.user_name, id: ref._id };
                })}
                disabled={status.loading}
                onChange={(event, refId) =>
                  onValueChange(refId.id, "user_ref_id")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputlabelprops={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        padding: "0.43em",
                        fontSize: "1.2em",
                      },
                      startAdornment: (
                        <>
                          <InputAdornment position="end">
                            <IconButton edge="center">
                              <Icon
                                children="supervised_user_circle"
                                className={appStyle.input_icons}
                              />
                            </IconButton>
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        )}

      {user.user_role >= UserRoles.supervisor &&
        loggedUser.role !== UserRoles.supervisor &&
        apps.length > 0 && (
          <Grid item xs={12} sm={loggedUser.role === UserRoles.admin ? 12 : 6}>
            <FormControl fullWidth>
              <Select
                className={appStyle.inputs}
                inputlabelprops={{ shrink: true }}
                disabled={status.loading}
                value={user.user_app}
                onChange={(event) =>
                  onValueChange(event.target.value, "user_app")
                }
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="center">
                      <Icon children="apps" className={appStyle.input_icons} />
                    </IconButton>
                  </InputAdornment>
                }
              >
                {apps
                  .filter((app) =>
                    loggedUser.role === UserRoles.merchant
                      ? app.app_name != null
                      : app.app_owner === user.user_ref_id
                  )
                  .map((app, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={app._id}
                        className={appStyle.inputs}
                      >
                        &nbsp;&nbsp;&nbsp;{app.app_name}
                        <br />
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        )}

      {user.user_role === UserRoles.merchant && (
        <Grid item xs={12} mt={2}>
          {progress > 0 && progress <= 100 && (
            <LinearProgress
              sx={{ mb: 3 }}
              value={progress}
              variant="determinate"
            />
          )}
          <Dropzone
            onChangeStatus={handleChangeStatus}
            maxFiles={1}
            disabled={status.loading}
            accept={acceptedMimes}
            maxSizeBytes={maxFileSize}
            submitButtonDisabled={true}
            submitButtonContent={null}
            inputContent={(files, extra) => {
              return extra.reject
                ? "Only images or PDF are allowed"
                : "Click  or Drag & Drop to add your Identification Document";
            }}
            styles={{
              dropzone: { minHeight: 100, maxHeight: 150 },
              dropzoneReject: {
                borderColor: "red",
                backgroundColor: "#DAA",
              },
              inputLabel: (files, extra) =>
                extra.reject
                  ? { color: "red" }
                  : { color: "black", fontWeight: 500, fontSize: "1em" },
            }}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={7}>
        <FormControlLabel
          checked={user.user_2fa_enabled}
          disabled={status.loading}
          onChange={(event) =>
            onValueChange(event.target.checked, "user_2fa_enabled")
          }
          control={<Checkbox />}
          label={
            <Typography className={appStyle.inputs} variant="body2">
              Enable two factor authentication <strong>(2FA)</strong> for this
              account
            </Typography>
          }
        />
      </Grid>

      <Grid item xs={12} sm={5}>
        <FormControlLabel
          checked={user.user_auto_disburse}
          disabled={status.loading}
          onChange={(event) =>
            onValueChange(event.target.checked, "user_auto_disburse")
          }
          control={<Checkbox />}
          label={
            <Typography className={appStyle.inputs} variant="body2">
              Enable auto disbursements
            </Typography>
          }
        />
      </Grid>
    </EditPage>
  );
};

export default UserEdit;
