import { Grid, IconButton, Icon } from "@mui/material";
import {
  guardRoute,
  getPaymentStatus,
  fCurrency,
  maskString,
  formatDate,
  getParams,
  subscribe,
  liveData,
  navigate,
} from "../utils/utils";
import Page from "./component/page";
import { useState, useEffect } from "react";
import DataList from "./component/@list/list";
import AppRoutes from "../routing/routes";

const getHeader = () => {
  const ticket = getParams().ticket;
  var header = [
    { id: "trans_timestamp", label: "Trans DateTime", alignRight: false },
    { id: "trans_reference_id", label: "Trans ID", alignRight: false },
    { id: "trans_client_id", label: "Client ID", alignRight: false },
    { id: "trans_amount", label: "Amount", alignRight: false },
  ];
  if (ticket) {
    header = header.concat([
      {
        id: "trans_max_purchase",
        label: !ticket ? "Paid" : "Tickets",
        alignRight: false,
      },
    ]);
  }
  header = header.concat([
    { id: "trans_channel_name", label: "Channel", alignRight: false },
    { id: "trans_status", label: "Status", alignRight: false },
    { id: "_" },
  ]);
  return header;
};

const InstalmentSummary = () => {
  guardRoute();
  const [transList, setTransList] = useState([]);
  const [loading, onLoading] = useState(false);
  const [filterKey, setFilterkey] = useState("trans_timestamp");
  const { ticket } = getParams();
  const [id] = useState(getParams().id);

  const filterOptions = [
    { value: "trans_client_id", label: "Phone Number", onClick: setFilterkey },
    {
      value: "trans_reference_id",
      label: "Transaction ID",
      onClick: setFilterkey,
    },
    { value: "trans_channel_name", label: "Channel", onClick: setFilterkey },
    { value: "trans_status", label: "Status", onClick: setFilterkey },
  ];

  liveData(
    "transaction",
    (match) => id === match.trans_transaction_id,
    () => executeFetch(false)
  );

  const executeFetch = (refresh = true) => {
    const path = `transaction/findByRange?range=year&instalment=false&ref=${id}`;
    subscribe(
      path,
      null,
      (_, result) => {
        if (result) setTransList(result);
      },
      (loading) => onLoading(refresh ? loading: false)
    );
  };

  useEffect(() => {
    executeFetch();
    // eslint-disable-next-line
  }, [id]);

  return (
    <Page title="Dashboard">
      <Grid container>
        <Grid item xs={12}>
          <DataList
            sectionTitle={ticket ? "Ticket Sold" : "Instalments made"}
            data={transList}
            primaryKey="_id"
            filterColumn={filterKey}
            emptyIcon="receipt_long"
            loading={loading}
            headers={getHeader()}
            rowData={(data) => {
              return {
                exclude: [],
                entries: [
                  {
                    value: formatDate(data.trans_timestamp, "hh:mm A, DD MMM YY"),
                    width: 160,
                  },
                  { value: maskString(data.trans_reference_id, 10, 3) },
                  { value: data.trans_client_id },
                  { value: fCurrency(data.trans_amount) },
                  {
                    value: ticket ? data.trans_max_purchase || 0 : null,
                  },
                  { value: data.trans_channel_name },
                  { ...getPaymentStatus(data.trans_status), width: 80 },
                  {
                    value: (
                      <IconButton
                        onClick={() =>
                          navigate(`${AppRoutes.insights}?id=${data._id}`, true)
                        }
                      >
                        <Icon children="query_stats" />
                      </IconButton>
                    ),
                    width: 50,
                  },
                ],
              };
            }}
            selectionOptions={[]}
            filterOptions={filterOptions}
            optionItems={[]}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default InstalmentSummary;
