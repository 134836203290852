import {
  Grid,
  FormControl,
  OutlinedInput,
  Typography,
  FormControlLabel,
  InputAdornment,
  Icon,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import { coreStyles } from "../theme/style";
import {
  getParams,
  validateUrl,
  getActiveUser,
  getStartTimeAsDate,
  getStartTimeInHours,
  navigate,
  UserRoles,
  isError,
  validateFields,
  cleanObject,
  showSnack,
  subscribe,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";

const appRef = {
  app_name: "",
  app_timestamp: new Date().getTime(),
  app_callback_url: "https://api.easypay.co.tz/v2/webhook/default",
  app_mobile_supported: true,
  app_card_supported: true,
  app_operation_hours: 24,
  app_operation_start: 7,
  app_id: null,
  app_features: [
    { id: 1, label: "Bill Merchants", allowed: true, order: 2 },
    { id: 2, label: "Bill Istantly", allowed: true, order: 1 },
    { id: 3, label: "Create Pay Links", allowed: false, order: 3 },
    { id: 4, label: "Bill Per Instalments", allowed: false, order: 4 },
    { id: 5, label: "Create Event Tickets", allowed: false, order: 5 },
  ],
  app_owner: "",
};

const validationRef = {
  app_name: { error: true, touched: false },
  app_callback_url: { error: false, touched: true },
};

const AppEdit = () => {
  const loggedUser = getActiveUser();
  const maxCharacters = 30;
  const appStyle = coreStyles();
  const { id } = getParams();
  const [app, setApp] = useState(appRef);
  const [validation, setValidation] = useState(validationRef);
  const [validated, setValidated] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });

  useEffect(() => {
    setTimeout(() => {
      Promise.all([
        subscribe(`user/find?role=${UserRoles.merchant}`),
        subscribe(`apps/find?id=${id || "-"}`, null, (loading) =>
          setStatus({ ...{ loading: loading } })
        ),
      ]).then((response) => {
        const merchants = response[0];
        const merchant = merchants[0];
        const remoteApp = response[1] || {};

        if (remoteApp && remoteApp.app_name) {
          onValueChange(remoteApp.app_name || "", "app_name");
          onValueChange(remoteApp.app_id || appRef.app_id, "app_id");
          const features =
            remoteApp.app_features &&
            remoteApp.app_features[0].order &&
            remoteApp.app_features[0].order > 0
              ? remoteApp.app_features
              : appRef.app_features;
          features.sort((prev, next) => next.order > prev.order ? -1: 1)
          onValueChange(features, "app_features");
          onValueChange(remoteApp.app_callback_url || "", "app_callback_url");
        }
        onValueChange(
          remoteApp.app_owner
            ? remoteApp.app_owner
            : merchant._id
            ? merchant._id
            : null,
          "app_owner"
        );
        setMerchants(merchants);
      });
    }, 500);
    return () => {
      setApp(appRef);
    };
    // eslint-disable-next-line
  }, [id]);

  const createUpdateApp = () => {
    if (loggedUser.role === UserRoles.merchant) {
      app.app_owner = loggedUser.id;
    }
    subscribe(
      `apps/${id ? "update" : "create"}`,
      cleanObject({ ...app, _id: id }),
      (error, result) =>
        setStatus({
          ...{
            error: error != null,
            message: result ? result.message : error.message,
          },
        }),
      (loading) => setStatus({ ...{ loading: loading } })
    );
  };

  const updateAppName = (value) =>
    onValueChange(
      value.length > maxCharacters ? value.substring(0, maxCharacters) : value,
      "app_name"
    );

  const onValueChange = (value, tag) => {
    app[tag] = value;
    if (tag.includes("_name")) {
      validation.app_name = { error: value.length <= 4, touched: true };
    }

    if (tag.includes("_callback")) {
      validation.app_callback_url = {
        error: !validateUrl(value),
        touched: true,
      };
    }
    setApp({ ...app });
    setValidation({ ...validation });
    setValidated(validateFields(validation));
  };

  return (
    <EditPage
      title={id ? `Edit ${app.app_name}` : "Create App"}
      status={status}
      onSave={() => createUpdateApp()}
      validated={validated && !status.loading}
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setApp({ ...appRef });
          navigate(`${AppRoutes.apps}`, true);
        }
      }}
    >
      <Grid
        item
        xs={12}
        sm={
          (merchants.length > 0 && loggedUser.role === UserRoles.admin) ||
          loggedUser.role === UserRoles.merchant
            ? 6
            : 12
        }
      >
        <EasyTextEdit
          placeholder="Application name"
          type="text"
          id="app_name"
          errorText="Invalid app name"
          error={isError(validation.app_name)}
          disabled={status.loading}
          value={app.app_name}
          icon="apps"
          onChange={(value, _) => updateAppName(value)}
          endAdornment={
            <InputAdornment position="end">
              <Typography
                sx={{ mr: 4 }}
                variant="subtitle1"
                color="primary.light"
                edge="end"
              >
                {app.app_name.length} / {maxCharacters}
              </Typography>
            </InputAdornment>
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <EasyTextEdit
          placeholder="Application ID"
          type="text"
          id="app_id"
          error={false}
          disabled={true}
          value={app.app_id || `Public ID not assigned`}
          icon="verified"
          onChange={(value, _) => {}}
        />
      </Grid>

      {merchants.length > 0 &&
        app.app_owner &&
        loggedUser.role === UserRoles.admin && (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Autocomplete
                filterOptions={(x) => x}
                disabled={status.loading}
                value={
                  merchants.filter((merc) => merc._id === app.app_owner)[0]
                    .user_name || "Hello"
                }
                options={merchants.map((ref) => {
                  return { label: ref.user_name, id: ref._id };
                })}
                onChange={(_, refId) => onValueChange(refId.id, "app_owner")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputlabelprops={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        padding: "0.43em",
                        fontSize: "1.2em",
                      },
                      startAdornment: (
                        <>
                          <InputAdornment position="end">
                            <IconButton edge="center">
                              <Icon
                                children="supervised_user_circle"
                                className={appStyle.input_icons}
                              />
                            </IconButton>
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        )}

      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileTimePicker
            value={getStartTimeAsDate(app.app_operation_start)}
            inputlabelprops={{ shrink: true }}
            minTime={getStartTimeAsDate(7)}
            onChange={(value) => {
              onValueChange(getStartTimeInHours(value), "app_operation_start");
            }}
            renderInput={(params) => (
              <FormControl variant="filled" fullWidth>
                <OutlinedInput
                  {...params}
                  placeholder="Operation start at"
                  type="text"
                  disabled={status.loading}
                  name="app_operation_start"
                  className={appStyle.inputs}
                  startAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="center">
                        <Icon
                          children="query_builder"
                          className={appStyle.input_icons}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            )}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <Select
            className={appStyle.inputs}
            inputlabelprops={{ shrink: true }}
            disabled={status.loading}
            value={app.app_operation_hours}
            onChange={(event) =>
              onValueChange(event.target.value * 1, "app_operation_hours")
            }
            startAdornment={
              <InputAdornment position="end">
                <IconButton edge="center">
                  <Icon
                    children="hourglass_bottom"
                    className={appStyle.input_icons}
                  />
                </IconButton>
              </InputAdornment>
            }
          >
            {[8, 12, 24].map((hour) => {
              return (
                <MenuItem value={hour} className={appStyle.inputs}>
                  &nbsp;&nbsp;&nbsp;<strong>{hour} </strong>{" "}
                  &nbsp;&nbsp;&nbsp;Work hours / day
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={loggedUser.role === UserRoles.admin ? 6 : 12}>
        <EasyTextEdit
          placeholder="Callback URL"
          type="text"
          id="app_callback_url"
          errorText="Invalid callback URL"
          error={isError(validation.app_callback_url)}
          disabled={status.loading}
          value={app.app_callback_url}
          icon="public"
          onChange={onValueChange}
        />
      </Grid>

      <Grid container sx={{ px: 6, mt: 4 }}>
        <Grid item xs={12} mb={2}>
          <Typography color="text.disabled">Features to enable</Typography>
        </Grid>
        {app.app_features.map((feature) => (
          <Grid item md={3} xs={12}>
            <FormControlLabel
              checked={feature.allowed}
              control={<Checkbox />}
              onChange={(event) => {
                if (feature.id > 2) {
                  feature.allowed = event.target.checked;
                  onValueChange(
                    app.app_features.map((_feature) =>
                      _feature.id === feature.id ? feature : _feature
                    ),
                    "app_features"
                  );
                } else {
                  showSnack("Default feature, can't be disabled");
                }
              }}
              label={<Typography variant="body1">{feature.label}</Typography>}
            />
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12} sm={7}>
        <Grid item xs={12} mb={2}>
          <Typography color="text.disabled">Supported Payments</Typography>
        </Grid>
        <FormControlLabel
          disabled={true}
          value={app.app_mobile_supported}
          control={<Checkbox defaultChecked />}
          label={
            <Typography className={appStyle.inputs} variant="body2">
              Enable mobile payments <strong>(All Supported Networks)</strong>
            </Typography>
          }
        />
      </Grid>

      <Grid item xs={12} sm={6} className={appStyle.hide}>
        <FormControlLabel
          disabled={true}
          value={app.app_card_supported}
          control={<Checkbox defaultChecked />}
          label={<Typography variant="h1">Enable card payment</Typography>}
        />
      </Grid>
    </EditPage>
  );
};

export default AppEdit;
