import Icon from "@mui/material/Icon";
import { logout } from "../utils/utils";
import AppRoutes from "./routes";
import { styled } from "@mui/material/styles";

const ListIconStyle = styled(Icon)({
  fontSize: "1.7rem !important",
});

const getIcon = (name) => <ListIconStyle children={name} />;

const navConfig = [
  {
    title: "Home",
    path: `${AppRoutes.dashboard}/${AppRoutes.summary}`,
    icon: getIcon("dashboard"),
  },
  {
    title: "Apps",
    path: `${AppRoutes.dashboard}/${AppRoutes.apps}`,
    icon: getIcon("apps"),
  },
  {
    title: "Channels",
    path: `${AppRoutes.dashboard}/${AppRoutes.channels}`,
    icon: getIcon("contactless"),
  },
  {
    title: "Charges",
    path: `${AppRoutes.dashboard}/${AppRoutes.charges}`,
    icon: getIcon("paid"),
  },
  {
    title: "Users",
    path: `${AppRoutes.dashboard}/${AppRoutes.users}`,
    icon: getIcon("groups"),
  },
  {
    title: "Disbursements",
    path: `${AppRoutes.dashboard}/${AppRoutes.disbursement}`,
    icon: getIcon("paid"),
  },
  {
    title: "Mailing",
    path: `${AppRoutes.dashboard}/${AppRoutes.mail}`,
    icon: getIcon("email"),
  },
  {
    title: "Account",
    path: `${AppRoutes.dashboard}/${AppRoutes.account}`,
    icon: getIcon("admin_panel_settings"),
  },
  {
    title: "Reports",
    path: `${AppRoutes.dashboard}/${AppRoutes.reports}`,
    icon: getIcon("pie_chart"),
  },
  {
    title: "Documentation",
    path: ``,
    icon: getIcon("integration_instructions"),
    action: () =>
      window.open("https://easypaytz.readme.io/reference/welcome", "_blank"),
  },
  {
    title: "Logout",
    path: ``,
    icon: getIcon("logout"),
    action: () => {
      logout();
    },
  },
];

export default navConfig;
