import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter} from "react-router-dom";
import ThemeProvider from "./theme/index";
import { Provider } from "react-redux";
import './style.css';
import AppRouter from './routing/app-router';
import AppStore from './utils/@redux/store';
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { initApp } from './utils/utils';
if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}
const root = ReactDOM.createRoot(document.getElementById("root"));
initApp();
document.body.style.zoom = "90%";
root.render(
  <Provider store={AppStore}>
    <ThemeProvider>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);