import ReCAPTCHA from "react-google-recaptcha";
import {
  CircularProgress,
  Typography,
  Link,
  Grid,
  Card,
  Button,
  Box,
  Icon,
} from "@mui/material";
import { coreStyles } from "../theme/style";
import { useState, useEffect } from "react";
import { guardRoute, isError, subscribe, validateEmail, validateFields } from "../utils/utils";
import AppRoutes from "../routing/routes";
import Logo from "./component/logo";
import Status from "./component/status";
import EasyTextEdit from "./component/text-edit";

const label = "Retrive Password";
const authRef = { email: ""};
const validationRef = {
  email: { error: true, touched: false },
  capture: { error: true, touched: false },
};

const AuthForgotPassword = () => {
  guardRoute(false);
  const appStyle = coreStyles();
  const [onProcessing, setOnProcessing] = useState(false);
  const [authProps, setAuthProps] = useState(authRef);
  const [btnLabel, setLabel] = useState(label);
  const [validated, setValidated] = useState(false);
  const [validation, setValidation] = useState(validationRef);
  const [status, setStatus] = useState({error: false,message: null});

  const handleSubmit = (event) => {
    event.preventDefault();
    setLabel("");
    subscribe(
      "auth/recoverPassword",
      authProps,
      (error, result) => {
        setLabel(label);
        setStatus({
          ...{
            error: error != null,
            message: result ? result.message : error.message,
          },
        });
      },
      (loading) => {
        setLabel("");
        setOnProcessing(loading);
      }
    );
  };

  const onValueChanged = (value) => {
    authProps["email"] = value;
    validation.email = { error: !validateEmail(value), touched: true };
    update();
  };

  const onCaptchaChange = (value) => {
    validation.capture = { error: value.length < 20, touched: true };
    update();
  };

  const update = () => {
    setAuthProps({ ...authProps });
    setValidation({ ...validation });
    setValidated(validateFields(validation));
  }

  useEffect(() => {
    // eslint-disable-next-line
  }, [validation, btnLabel, onProcessing]);

  return (
    <Grid
      container
      className={appStyle.container}
      justifyContent="center"
      elevation={4}
      alignItems="center"
    >
      <Grid item textAlign="center" xs={12}>
        <Logo image="easypay-logo-white.svg" />
      </Grid>
      <Grid item xs={11} md={5}>
        <Card className={appStyle.loginCard}>
          <Typography
            variant="h2"
            align="center"
            color="primary.darker"
            mb={4}
            className={
              !status.error && status.message ? appStyle.hide : appStyle.title
            }
          >
            Forgot Password
          </Typography>
          <Grid container justifyContent="center">
            <Grid
              item
              mt={6}
              mb={5}
              className={
                !status.error && status.message ? appStyle.show : appStyle.hide
              }
            >
              <Icon
                children="check_circle_outline"
                color="primary"
                className={appStyle.icon_standalone}
              />
            </Grid>
            <Grid
              item
              mb={4}
              xs={12}
              className={
                !status.error && status.message ? appStyle.hide : appStyle.show
              }
            >
              <Typography
                align="center"
                variant="body1"
                className={
                  !status.error && status.message
                    ? appStyle.hide
                    : appStyle.sub_title
                }
              >
                Enter your email address to retrieve your password
              </Typography>
            </Grid>
            {status && status.message && status.error && (
              <Grid item xs={12}>
                <Status
                  error={status.error}
                  timeout={5000}
                  message={status.message}
                  onClose={() => {
                    if (status.error) {
                      setStatus({ ...{ message: null } });
                    } else {
                      //navigate(AppRoutes.login, true);
                    }
                  }}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              mb={8}
              className={
                !status.error && status.message ? appStyle.show : appStyle.hide
              }
            >
              <Typography
                align="center"
                variant="h4"
                className={appStyle.textPadding}
              >
                {status.message}
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Box
            noValidate
            sx={{ mt: 1, mb: 6 }}
            className={
              !status.error && status.message ? appStyle.hide : appStyle.show
            }
          >
            <EasyTextEdit
              placeholder="Email address"
              type="email"
              id="email"
              errorText="Invalid email address"
              error={isError(validation.email)}
              disabled={onProcessing}
              value={authProps.email}
              icon="alternate_email"
              onChange={(value, _) => onValueChanged(value)}
              sx={{ mt: 1, mb: 4 }}
            />

            <Grid container justifyContent="center">
              <Grid item sx={{ mb: 2 }}>
                <ReCAPTCHA
                  sitekey="6Les68UfAAAAABv9dQCrFBCGjeo4A86aJ2uqyr3R"
                  size="normal"
                  onChange={onCaptchaChange}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!(validated && !onProcessing)}
              className={appStyle.loginButton}
            >
              {onProcessing && <CircularProgress color="primary" />}
              {btnLabel}
            </Button>
          </Box>
          <p align="center">
            <Link
              href={AppRoutes.login}
              variant="body2"
              className={appStyle.links}
            >
              &nbsp;&nbsp;Go back to Login
            </Link>
          </p>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AuthForgotPassword;
