import { Navigate, useRoutes } from "react-router-dom";
import AppEdit from "../views/app-edit";
import AppList from "../views/apps";
import Auth2FaAuth from "../views/auth-2fa";
import Auth2FaConfig from "../views/auth-2fa-qr";
import AuthForgotPassword from "../views/auth-forgot-password";
import AuthLogin from "../views/auth-login";
import AuthResetPassword from "../views/auth-reset-password";
import Dashboard from "../views/dashboard";
import UserList from "../views/users";
import AppRoutes from "./routes";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateNavigator } from "../utils/@redux/navigator";
import { setSocket } from "../utils/@redux/socket";
import UserEdit from "../views/user-edit";
import RevenueSummary from "../views/revenue-summary";
import DisbursementSummary from "../views/disbursements";
import DisburseEdit from "../views/disburse-edit";
import AuthRegister from "../views/auth-register";
import AccountSetting from "../views/account-setting";
import Mailing from "../views/mailing";
import Payment from "../views/pay-push";
import Instalment from "../views/pay-instalment";
import InstalmentSummary from "../views/instalment-summary";
import Ticket from "../views/pay-ticket";
import Reports from "../views/reports";
import { io } from "socket.io-client";
import { baseUrl, getActiveUser, navigate } from "../utils/utils";
import ChannelList from "../views/channels";
import ChannelEdit from "../views/channel-edit";
import { useEffect } from 'react';
import AppStore from "../utils/@redux/store";
import Insights from "../views/insights";
import ChargeList from "../views/charges";
import ChargeEdit from "../views/charge-edit";

var socket = null;

export default function AppRouter() {
  useDispatch()(updateNavigator(useNavigate()));
  socket = AppStore.getState().socket.instance;
  if(socket === null){
    socket = io(baseUrl().replace("/v2/", ""), {
      transports: ["websocket"],
      secure: true,
      autoConnect: false,
    });
  }
  useDispatch()(setSocket(socket));
  
  useEffect(() => {
    socket.connect();
    if (window.location.href.includes(AppRoutes.auth) && getActiveUser()) {
      navigate(AppRoutes.summary, true);
    }
    return () => {
      socket.disconnect(true);
    };
    
    // eslint-disable-next-line
  }, []);

  return useRoutes([
    {
      path: `${AppRoutes.dashboard}`,
      element: <Dashboard />,
      children: [
        { path: `${AppRoutes.account}`, element: <AccountSetting /> },
        {
          path: ``,
          element: (
            <Navigate
              to={`${AppRoutes.dashboard}/${AppRoutes.summary}${window.location.search}`}
            />
          ),
        },
        { path: `${AppRoutes.summary}`, element: <RevenueSummary /> },
        { path: `${AppRoutes.insights}`, element: <Insights /> },
        {
          path: `${AppRoutes.apps}`,
          children: [
            { path: "", element: <AppList /> },
            { path: `${AppRoutes.app}`, element: <AppEdit /> },
            { path: `${AppRoutes.appRevenue}`, element: <RevenueSummary /> },
          ],
        },
        {
          path: `${AppRoutes.channels}`,
          children: [
            { path: "", element: <ChannelList /> },
            { path: `${AppRoutes.channel}`, element: <ChannelEdit /> },
          ],
        },
        {
          path: `${AppRoutes.charges}`,
          children: [
            { path: "", element: <ChargeList /> },
            { path: `${AppRoutes.charge}`, element: <ChargeEdit /> },
          ],
        },
        {
          path: `${AppRoutes.users}`,
          children: [
            { path: "", element: <UserList /> },
            { path: `${AppRoutes.user}`, element: <UserEdit /> },
          ],
        },
        {
          path: `${AppRoutes.mail}`,
          element: <Mailing />,
        },
        { path: `${AppRoutes.instalments}`, element: <InstalmentSummary /> },
        { path: `${AppRoutes.reports}`, element: <Reports /> },
        {
          path: `${AppRoutes.disbursement}`,
          children: [
            { path: "", element: <DisbursementSummary /> },
            { path: `${AppRoutes.requestPay}`, element: <DisburseEdit /> },
          ],
        },
      ],
    },
    {
      path: `${AppRoutes.auth}`,
      children: [
        { path: `${AppRoutes.login}`, element: <AuthLogin /> },
        { path: `${AppRoutes.twoFactorAuth}`, element: <Auth2FaAuth /> },
        { path: `${AppRoutes.twoFactorConfig}`, element: <Auth2FaConfig /> },
        { path: `${AppRoutes.register}`, element: <AuthRegister /> },
        { path: `${AppRoutes.forgot}`, element: <AuthForgotPassword /> },
        { path: `${AppRoutes.recover}`, element: <AuthResetPassword /> },
        {
          path: ``,
          element: (
            <Navigate
              to={`${AppRoutes.auth}/${AppRoutes.login}${window.location.search}`}
            />
          ),
        },
      ],
    },
    {
      path: `${AppRoutes.transaction}`,
      children: [
        { path: `${AppRoutes.checkout}`, element: <Payment /> },
        { path: `${AppRoutes.payout}`, element: <Instalment /> },
        { path: `${AppRoutes.ticket}`, element: <Ticket /> },
        {
          path: ``,
          element: (
            <Navigate
              to={`${AppRoutes.transaction}/${AppRoutes.pay}${window.location.search}`}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to={`${AppRoutes.auth}/${AppRoutes.login}`} replace />,
    },
  ]);
}
